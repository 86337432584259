.formCard{
    background: #FFFFFF;
box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
border-radius: 10px;

}

.lookUpBtn{
 padding-top:25px
}
.spclProv{
    .ant-select-item{
        text-transform: capitalize;
    }
}
.specialityCol{
    padding-right: 5%;
}

.formCardContainer{
    padding-top: 1.25rem;
}

.addressContainer{
    align-items: center;
}
.formCardContainer{
    .ant-checkbox + span {
        padding-right: 30px !important;
        padding-left: 8px;
    }
    .emailCheckbox{
        .ant-checkbox{
            top: 0!important;
        }
        
    }
}
.emailCheckbox .ant-checkbox + span {
   position: relative;
   top: -2px;
}
.specialityContainer{
    padding-top: 0.938rem;
}

.cameraImg2{
    position: relative;
    bottom: 4.97rem;
    left: 1.29rem;
}

.circleCamImg{
    position: relative;
    bottom: 5rem;
    left: 2.9rem;
}
.phoneInput .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    padding-right: 0px;
    height: 38px !important;
}
.provCheckboxemail{
    position: relative;
    left: 6.25rem;
    top: 1.25rem;
    margin-left: 0px !important;
}