.agreementHeader{
    display: flex;
    justify-content: center;
    font-family: 'Open Sans';
    font-size: 22px;
    font-weight: 600;
}
.consditionUse {
    border-top: 0.5px solid #2e2e2e;
    padding-top: 16px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: normal;
}

.consditionUse>div>b {
    font-size: 16px;
    text-transform: capitalize;
    font-family: 'Open Sans';
    margin-bottom: 10px;
    display: block;
}
.consditionUse p{
    margin-bottom: 2rem !important;
    text-align: justify;
}
.consditionUse p,.consditionUse p>b {
    font-size: 14px;
    font-family: 'Open Sans';
}

.consditionUse a {
color: rgb(15 141 227);
font-size: 14px;
font-weight: 600;
font-family: 'Open Sans';
}
.agreeText{
    font-size: 14px;
font-weight: 600;
font-family: 'Open Sans';
margin-top: 15px;
span{
    color: rgba(0, 0, 0, 0.85)
}

}
.agreementPolicy{
    box-shadow: 0px -1px 4px rgb(0 0 0 / 15%);
    padding: 10px 30px;
    border-radius: 5px;
   background: #fff;
}
.mainAgrmtPolicy{
    position: absolute;
    z-index: 9999;
    .ant-checkbox-inner{
        width: 22px !important;
        height: 22px !important;
    }
    .btncontinue{
        margin-bottom: 15px;
    }
}
