.cardAccountSettings{
    border-radius: 10px !important;
    min-height: 75vh;
}
.settingsDiv{
    padding-top: 1.25rem !important;
}
.accntset{
    padding-right: 2vw !important;
    padding-left: 1.5vw !important;
}
.headingAccountSettings{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #2E2E2E;
}
.patientCheckbox{
    .ant-checkbox-group{
        margin-top: 12px;
    }
}
.patientCheckbox{
    padding-right: 0 !important;
    padding-left: 0 !important;
}
@media(max-width:992px){
    .patientCheckbox{
        .ant-checkbox-group{
            margin-top: 12px;
        }
    }
    .cardAccountSettings .ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
        padding-right: 23px;
    }
}
.saveacc{
    padding-right: 0px!important;
}