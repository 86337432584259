.userModal {
    .ant-modal-content {
        border-radius: 10px;
        height: 100% ;
        display: flex !important;
        align-items: center;
        justify-content: center;
    }

    .ant-modal {
        height: 39%;
        // width: 35.14vw !important
    }
    .ant-modal-close-x svg{
        width: 15px;
        height: 15px;
    }

}

.redCoss{
    margin-bottom: 8px;
}
.userModalRow{
    margin-top: 5px;
}

.linkUserModal{
    color:#000099;
}