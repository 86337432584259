.alertCard{
background: #FFFFFF;
border-radius: 5px;
margin: 20px 30px 0px 30px !important; 
.ant-card-body{
    padding: 15px 20px 15px 20px;
}
}

.alertCardSubHeading{
font-family: 'Open Sans';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 120%;
display: flex;
align-items: left;
color: #898989;
}

.senderAlert{
    font-family: 'Open Sans';
font-style: normal;
font-weight: 600;
font-size: 12px;
line-height: 120%;
display: flex;
align-items: left;
color: #2E2E2E;
}

.messageAlert{
    font-family: 'Open Sans';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 160%;
display: flex;
align-items: left;
letter-spacing: 0.002em;
color: #2E2E2E;
p{
font-size: 12px !important;
margin-bottom: 0px !important;
}
}

.inactiveRed{
    margin-bottom: 10px;
}