.drTxt {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size:16px !important;
  line-height: 120%;
  display: flex;
  align-items: center;
  color: #000099 !important;
}
.bpCardTxt {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #2E2E2E;
}
.bpCard p{
  margin-bottom: 5px !important;
}
.bloodPressureTxt{
  font-family: 'Open Sans';
font-style: normal;
font-weight: 600;
font-size: 12px;
align-items: center;
letter-spacing: 0.05em;
color: #2E2E2E;
}
.systolicTxt {
  font-style: normal;
  font-weight: normal;
  line-height: 250%;
  display: flex;
  font-size: 12px !important;
  align-items: center;
  letter-spacing: 0.05em;
  font-family: 'Open Sans';
font-style: normal;
font-weight: 400;
color: #898989;
}

.bloodPressuretxt {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  line-height: 180%;

  display: flex;
  align-items: center;
  letter-spacing: 0.05em;

  color: #2e2e2e !important;
}
.systolicTxtNmbr {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  line-height: 250%;
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 12px !important;
  letter-spacing: 0.05em;
  color: rgba(46, 46, 46, 0.8);
}

.thresholdSelect {
  padding-top: 10px;
}

.diastolicTxt {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.05em;

  color: #2e2e2e;
}

.bpCardContainer {
  padding-top: 10px;
  padding-right: 0rem;
  .ant-card-body{
    padding: 24px 30px !important;
  }
}
.dividerHeight{
  height:5.4vh !important;
}

.inp {
  outline: none !important;
  border-width: 0px 0px 2px 0px !important;
  border-color: #2e2e2e !important;
  width: 100%;
  text-align: center;
  & :focus {
    outline: none !important;
    border-width: 0px 0px 2px 0px !important;
    box-shadow: 0px 0px 0px 0px !important;
  }
  & :active {
    outline: none !important;
    border-width: 0px 0px 2px 0px !important;
    box-shadow: none !important;
  }
  & :visited {
    outline: none !important;
    border-width: 0px 0px 2px 0px !important;
  }
  & :hover {
    outline: none !important;
    border-width: 0px 0px 2px 0px !important;
    border-right-width: 0px !important;
  }
  & :focus-visible {
    outline: none !important;
    border-width: 0px 0px 2px 0px !important;
  }
  & :target {
    outline: none !important;
    border-width: 0px 0px 2px 0px !important;
  }
}

.thresholdSaveBtn {
  padding-top: 20%;
}
.thresholdSaveBtn .secondary{
  display: block !important;
  padding: 0px 5px !important;
}
.diastolicColumn {
  padding-top: 4%;
}
.weightColumn {
  .diastolicTxt{

      display: block;
      text-align: center;
      align-items: center;
  }
}
.bpCard{
  background: #FFFFFF !important;
box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
border-radius: 10px !important;
}

.pencilIcon{
  cursor: pointer;
  width: 18px;
  height: 18px;
  color: rgba(137, 137, 137, 0.5);
  position: relative;
  cursor: not-allowed;
    top: 0;
    text-align: end;
    margin-right: 6px;
}
.notEditpencilIcon{
  position: relative;
  top: 0;
  width: 18px;
  height: 18px;
  color: #000099;
  cursor: pointer;
  margin-right: 6px;
}
.tempRdo{
  .ant-radio-inner{
    width: 20px;
    height: 20px;
  }
}
.ant-checkbox-wrapper.Obesity {
  margin-left: 0px !important;
}
.PHeartFObesity {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  color: #2E2E2E;
  margin-top: 20px;
  .ant-checkbox-wrapper {
    font-size:12px !important;
  }
  .ant-space-vertical{
    row-gap: 2px !important;
  }
  
}
.editView{
  margin-top: 22px;
  .heartFailure{
    margin-bottom: 11px;
  }
}
.PHeartFObesity{
  .ant-checkbox-inner {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: 16px;
    height: 16px;
    direction: ltr;
    background-color: #fff;
    border: 1px solid #89898980;
    border-radius: 2px;
    border-collapse: separate;
    transition: all 0.3s;
}
.ant-checkbox-disabled + span {
  cursor: not-allowed;
  color: #2E2E2E !important;
}
}

@media(max-width:992px){
  .mborder4Reverse{
    order: 3;
    padding-right: 130px;
    margin-top: 40px;
  }
  .mborder3Reverse{
    order: 4;
    margin-top: 40px;
    }
    .BPpatientName{
      margin-bottom: 20px;
    }
    .hideDivider{
      border: 0px !important;
    }
    .diastolicColumn {
      padding-top: 8%;
  }
  .mborderReverse{
    padding-right: 80px !important;
  }
  .thresholdSaveBtn{
    .primary, .secondary, .tertiary{
      font-size: 10px !important;
    }
  }
}