.adminUserRow{
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    height: 3.438rem;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 0.83vw;
    line-height: 120%;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #2E2E2E;
}