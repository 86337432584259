.brdUserName{
    font-family: Open Sans;
font-style: normal;
font-weight: 600;
line-height: 120%;
letter-spacing: 0.1em;
text-transform: uppercase;
color: #000099;
margin-bottom: 0px;
margin-top: 5px;
}

.userButtons{
    padding-bottom: 1.25rem;
    
}

.addUsers{
    border-bottom: 1px solid rgba(0, 0, 0, 0.1); 
}
