.mainPage{
    background-color: lavender;
    height: 100vh;
}
.new{
     .ant-card-body{
        padding-top: 4.375rem !important;
        padding-right: 4.625rem !important;
        padding-left: 4.688rem !important; 
        padding-bottom: 4.5rem !important;
        }
  }

.create{
    color: #000099;
    text-align: center;
    margin-bottom: 8px;
    margin-top: 0;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: 0.1em;
}

.read{
    color: rgb(15, 1, 1);
    text-align: center;
    margin-bottom: 0;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    line-height: 120%;
    letter-spacing: 0.05em;
    
}
.accountSettingModal{
    display: block !important;
}
.newPass{
    padding-top: 1.25rem;
    margin-bottom: 1.688rem;
}

.pass2{
    padding-bottom: 1.188rem;
}

.icon{
    padding-right: 0.406rem;
}
