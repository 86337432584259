@media screen and (min-width: 768px){
.row{
padding-bottom: 1.875rem;
}
.col{  
  padding-right: 0.938rem;
  padding-left: 0.938rem;
}}
.formContanier{
  padding-right: 1.75rem;
}
.payerBrd{
  margin-top: 10px !important;
}