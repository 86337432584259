.fradio{
    padding-top: 10px;
}
.cradio{
    text-align: center;
}
.fRadioGroup{
    font-family: Open Sans;
font-style: normal;
font-weight: normal;
line-height: 120%;
}

.glucoTxt span ,.fRadioGroup span {
    content: "\00B0";
  }

  .inp ::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }