.patientReviewModal {
    .ant-modal {
        width: 68.88vw !important;
    }

    .ant-modal-close-x svg {
        width: 9px;
        height: 9px;
    }

    .ant-modal-body {
        padding-right: 56px;
        padding-left: 56px;
        margin-top: 45px !important;
        // max-height: 600px;
        // overflow-y: auto;
    }

}

.ptntForm {
    table {
        thead {
            th {
                font-size: 10px !important;
            }
        }

        tbody {
            td {
                font-size: 10px !important;

            }
        }
    }
}

.patientReview {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 8px;
    align-items: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #2E2E2E;


}
.cancelBtnReview{
    border: 1px solid #000099;
}
.ptntHead {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 8px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.02em;
    color: #2E2E2E;
    margin-bottom: 17px;

}

.patientName {
    color: #000099;
}

.ptntBorder {
    border-bottom: 0.5px solid rgba(46, 46, 46, 0.3);
    margin-bottom: 39px;
    padding-bottom: 15px;
}

.ptntCheckbox {
    margin-bottom: 25px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    line-height: 120%;

    .ant-checkbox-wrapper {
        color: #2E2E2E !important;
    }
}

.ptntSelect {
    margin-bottom: 20px;
}

.addHead {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    color: #2E2E2E;
    margin-bottom: 16px;
}

.ptntText {
    margin-bottom: 19px;
}

.txtRecord {
    margin-top: 9px;
    border-top: 0.5px solid rgba(46, 46, 46, 0.3);
}

.grpRadio {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
    color: #2E2E2E;

    .ant-radio-wrapper {
        color: #2E2E2E !important;
        width: 15%;
        margin-bottom: 10px;
    }
}

.btnCacl {
    border: 1px solid rgba(0, 0, 153, 0.1);
    border-radius: 5px;
    font-size: 14px !important;
    font-weight: 700 !important;
}

.btnsubmit {
    font-weight: 700 !important;
    font-size: 14px !important;
}

.rdoBtn {
    margin-top: 17px !important;
    width: 100% !important;

    .primary {
        font-size: 14px;
    }

    .ant-radio-wrapper span {
        font-size: 12px !important;
    }
}

.lastRow {
    margin-bottom: 36px;
}

.txtReview {
    border-top: 0.5px solid rgba(46, 46, 46, 0.3);
}
.txtReviewbBottom{
    border-bottom: 0.5px solid rgba(46, 46, 46, 0.3);
    margin-bottom: 30px;
    .reviewNote {
        margin-bottom: 30px;
    }
}

.addEmailTxt{
    margin-top: 9px;
    border-top: 0.5px solid rgba(46, 46, 46, 0.3);
}

.txtHistory {
    margin-top: 43px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    color: #2E2E2E;
}

.patientreviewModal {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.ddldrgCode .ant-select-item-option-content{
font-size: 12px !important;
}
.patienttxt {
    text-align: right;
}

@media(max-width:992px) {
    .patientReviewModal .ant-modal-body {
        padding-right: 56px;
        padding-left: 56px;
        margin-top: 10px !important;
    }

    .patienttxt {
        text-align: left;
        margin-top: 15px;
    }

    .ptntCheckbox {
        .ant-checkbox-wrapper:last-child {
            margin-left: 0px !important;
        }

        .ant-checkbox-group {
            margin-top: 10px;
        }

        .ptntCheckbox>span {
            font-size: 12px !important;
        }
    }

    .grpRadio .ant-radio-wrapper {
        color: #2E2E2E !important;
        width: 25%;
    }

}