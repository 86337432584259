.addRolesRow{
    padding-bottom: 1.688rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-right: 1.75rem;
}

.add{
   width: 20px;
   height: 20px;
}
