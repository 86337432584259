.summaryCard{
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px !important;
  height: 99%;

}
.summaryCard .ant-card-bordered {
  border:1px solid rgba(137, 137, 137, 0.3) !important;
}
.summaryCard .emailIcon{
  width: 16.67px;
  height: 15px;
}
.summary-container{
  height: 100%;
  padding-bottom: 22px;
}
.summary{
  font-family: Open Sans;
font-style: normal;
font-weight: 600;
line-height: 120%;
}
.act{
  padding-left: 10px;
}
.Patsummary {
  width: 27px;
  height: 24px;
  fill: #898989;
}
.providerIcon span{
  color: #898989;
}
.green-button{
  color: #2E2E2E;
  margin-right: 11px;
}
.locasummary {
  width: 19.44px;
  height: 24.31px;
}
.adminsummary{
  width: 18px;
  height: 22px;
}
.psummary{
  width: 24px;
  height: 16.8px;
}
.Pasummary{
  width: 22px;
  height: 16px;
}
.Text{
display: flex;
align-items: center;
text-align: center;
text-transform: uppercase;
color: #2E2E2E;
}

.Status{
  margin-top: 10px;
  font-weight: normal;
}

.pencil{
 align-items: right;
 cursor: pointer;
 width: 22.5px;
 height: 22.5px;
}

.organName{
letter-spacing: 0.1em;
text-transform: uppercase;
color: #000099;
margin-top: 1.875rem;
text-align: center;
}
.organName p{
line-height: 1.5em;
}


.primeContact{
display: flex;
align-items: center;
text-align: center;
text-transform: uppercase;
color: #000000;
margin-top: 2.063rem;

}

.primeName{

  display: flex;
  align-items: center;
  text-align: center;
  color: #000099;
  margin-top: 1rem;
}

.primeDetails{
font-weight: normal;
display: flex;
align-items: center;
text-align: center;
color: #2E2E2E;
margin-top: 1.313rem;
}

.emailIcon{
  margin-right: 1.292rem;
}

.detailCard{
  border: 1px solid rgba(137, 137, 137, 0.3);
box-sizing: border-box;
border-radius: 10px;


}

.iconCircle{
  border-radius: 100%;
  background: rgba(137, 137, 137, 0.3);
  height: 2.813rem;
  width: 2.813rem;
  justify-content: center;
  align-items: center;
  display: flex;
}

.summaryIcon{
  height: 1.25rem;
  width: 1.25rem;
}

.CardDetails{
align-items: center;
text-align: center;
text-transform: uppercase;
color: #2E2E2E;
justify-content: center;

}

.Count{
display: flex;
align-items: center;
text-align: center;
text-transform: capitalize;
color: #000099;
margin-top: 2px;
}

.showButton{
  text-align: end;
}

.summaryCard{
  .showButton{
    img{
      opacity: 0.4;
    }
  }
}
.Notes{
align-items: center;
text-align: center;
text-transform: uppercase;
color: #2E2E2E;
}

.summaryContainer{
  margin-right: 1.125rem;
}

@media only screen and (max-width: 992px) {
  .NotesRow{
    margin-top: 0;
  }
}

.primaryRowOrgSummary{
  margin-top: 27px;
  border: 1px solid rgba(0, 0, 0, 0.05) !important;
  margin-bottom: 27px;
}

.rightPrimaryRowOrgSummary{
  margin-top: 14%;
}
.hrMargin{
  margin-top: 0px;
}
.edicn{
  text-align: end;
}
.emailPaientDetails{
  display: flex;
  p{
    margin: 0px !important;
  }
}
@media(max-width:1024px){
  .emailPaientDetails{
    width: 100%;
    p{
      margin: 0px !important;
    }
  }
}
@media(min-width:992px)and(max-width:1200px){
    .summaryCard{
      .detailCard{
        .ant-card-body{
          padding: 12px;
        }
      
      }
    }
    .organization-tabs .ant-tabs-tab-btn{
      font-size: 12px;
    }
  }
  @media(min-width:768px)and(max-width:1200px){
    .organization-tabs .ant-tabs-tab-btn{
      font-size: 12px;
    }
  }
  .rigtSummryNote{
    text-align: justify;
    display: block;
  }
