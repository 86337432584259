.familyTableContainer{
    border-radius: 10px !important;
    min-height: 72vh;
}
.familyTable{
    padding-top: 2.19%;
    .deleteIcon{
        text-align: center;
    }
}
