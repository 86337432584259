.familyCardHead{
    font-family: 'Open Sans';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 120%;
text-transform: uppercase;
justify-content: center;

}


.patientFamily {
    padding-top: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #ddd;
}
.familyModal {
    .ant-modal-body{
        padding: 35px !important;
    }
    .ant-modal-content{
            background: #FFFFFF !important;
        box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1) !important;
        border-radius: 10px !important;
    } 
    .ant-modal {
        width: 750px !important;
    }
}

.patientFamily{
    padding-top: 15px;
}

.family{
    font-family: 'Open Sans';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 120%;
color: #898989;
}

.familyName{
    padding-left: 5px;
    margin-right: 5px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    color: #000099;
    text-transform: capitalize;
}
.sendReqBtn{
    padding-top: 20px;
    justify-content: end;
}