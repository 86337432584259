.dashBoardPage{
    font-family: Open Sans;
font-style: normal;
font-weight: 600;
line-height: 120%;
letter-spacing: 0.1em;
text-transform: uppercase;
color: #000099;
}
.graphCard{
    .ant-card-body{
        padding:20px !important;
    }
}
.dashboard.chartWrapper{
    height: 290px;
    overflow: hidden;
}
.nodataText{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 26px;
    color: #DC143C;
    position: absolute;
    top: 45%;
    left: 40%;
}
#chartdiv,#chartdivpatient,#chartdivorggrowth{
    width: 100%;
}
#chartdivbiometric,#chartdivpatientreview{
    width: 100%
}
@media(min-width:992px)and (max-width:1240px){
    #chartdiv,#chartdivpatient,#chartdivorggrowth{
        width: 110% !important;
    }
}
@media(min-width:992px)and(max-width:1200px){
    #chartdivbiometric,#chartdivpatientreview{
        width: 105% !important;
    }
}