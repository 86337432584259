.providerButton{
    height: 40px;
}

.eventHistorButton{
    margin-bottom: 20px;
    padding-right: 2vw;
    padding-left: 1.5vw;
    .providerButton{
        margin-top: 0px !important;
        height: 40px !important;
        padding: 6px;
    }
    .dobPicker{
        height: 40px !important;
        margin-top: 0px !important;
        .ant-picker-input > input{
            font-size: 12px;
        }
    }
    .textFont{
        text-align: left !important;
        font-family: 'Open Sans';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 120%;
letter-spacing: 0.05em;
text-transform: uppercase;

color: #2E2E2E;
    }
}


.eventHistoryTable{
    font-family: Open Sans;
    .selected{
        background: #F5F6FA;
        td{
            padding:0px;
            .cellRenderer{
                border-left: 3px solid #000099 !important;
                align-items: center;
                padding: 10px 10px 0.938rem 1.875rem;
                border: 1px solid #E9ECEF;
            }
        }
        }
    thead{
        tr{
            th{
                
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                text-align: left;
                text-transform: uppercase !important;
                color: #000099;
                background: white;
                }
                th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before{
                    height: 3.6em !important;
                }
        }
        
    }
    tbody tr:first-child{
       background-color: rgba(137, 137, 137, 0.12) !important;
        
    }
 
    .departmentTable{
        thead{
            display: none;
        }
    }
    td{
        height: 2.5rem;
        border: 1px solid rgba(137, 137, 137, 0.1);
        font-size: 14px;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 120%;
        color: #2E2E2E;
    }


    .ant-table-expanded-row-level-1{
        td{
            height: 2.5rem;
            border: 1px solid rgba(137, 137, 137, 0.1);
        }
    }
}

.eventHistoryCard{
box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
border-radius: 10px !important;
min-height: 60vh;
}

.emailProviderHistory{
    font-weight: 600;
    .textFont{
        font-size: 16px;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 120%;
        letter-spacing: 0.05em;
        text-transform: uppercase;
    }
 
}

.editIcon{
    margin-top: 43px;
}
