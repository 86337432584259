.graphCard{
    padding-left: 10px;
    min-height: 100%;
}
.graphCardContainer{
    padding: 10px 0px 10px 0px;
    .providerGraphCard{
        min-height: 130px!important;
        .totalPatientTxt{
            line-height: 120% !important;
        }
    }
}
.orgDailyRoutine{
    font-family: 'Open Sans';
font-style: normal;
font-weight: 600;
font-size: 12px;
}
@media(max-width:992px){
    .graphCardContainer .material-icons-outlined{
    font-size:30px!important; 
    }
}
.legendAlign{
    display: flex;
    justify-content: space-between;
    width: 540px;
}