.patientDevices-container{
    background: #FFFFFF;
box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
border-radius: 10px;
padding: 1.5rem 1.875rem 1.5rem 1.875rem;
}

.patientDevicesInput{
  align-items: center;  
}

.tableTitle{
    font-family: Open Sans;
font-style: normal;
font-weight: 600;
font-size: 1.11vw;
line-height: 120%;
letter-spacing: 0.05em;
text-transform: capitalize !important;
color: #2E2E2E;

}
.hubTable table thead>tr>th , .deviceTable table thead>tr>th{
    padding: 12px 18px !important;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    align-items: center;
    text-transform: uppercase;
    
    color: #000099;
}

.devicesTable-container{
    margin-top: 0.938rem;
}
@media(min-width:1024px){
    .patientDevicesInput .ant-checkbox-wrapper{
        margin-left: 0px !important;
    }
}

.typeBiometric{
    text-transform: capitalize;
}
.assncheck{
    .ant-checkbox + span{
        padding-right: 21px !important;
    }
    .ant-checkbox{
        width: 20px;
        height: 20px;
        display: flex;
    }
}