.brdOrg {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #000099;
}

.orgListHead {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  align-items: center;
  // text-align: center;
  text-transform: uppercase;
  color: #2e2e2e;
  // display:flex;
    margin-left: 12px;
}
.orgListHeadMgin{
  display: flex !important;
  justify-content: flex-start !important;
}

.orgCardColumn {
    margin-right: 0px;
  }
.listing {
  text-align: right;
}
.activeBtntxt {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 0.97vw;
  line-height: 1.188rem;
  text-align: center;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: #2e2e2e;
}
.activeBtn {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.brdCrumbOrg {
  padding-right: 8px;
  padding-bottom: 1.25rem;
}

.resetBtn {
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  border-radius: 7px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  line-height: 1.188rem;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: capitalize;
  color: #898989;
  background-color: #ffffff;
}

.addOrgBtntxt {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 0.97vw;
  line-height: 1.188rem;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #000099;
}
.txtSize{
  padding-right: 4px;
}
.btnSize{
  line-height: 0.188rem;
}
.iconColor{
  color:#000099;
}

.imgSize{
  width: 100%;
  height: 100%;
}

.addOrgBtn {
  border: 1px solid #000099;
  box-sizing: border-box;
  border-radius: 5px;
  font-size: 14px !important;
  font-weight: 600px !important;
}

.addIconImage {
  color: #ffffff !important;
}
.brdCrumbOrg {
  padding-right: 8px;
}

.navbarIcon {
  cursor: pointer;
}
.vectorClass {
  cursor: pointer;
  float: right;
  margin-right: 5px;
}
.funnelImage {
  padding-right: 5px;
}
.resetImg {
  padding-right: 5px;
}

.addIconImage {
  padding-right: 5px;
}

.upImage {
  padding-left: 10px;
  padding-right:5px;
}
.tabLine {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 0.938rem;
  padding-top: 0.938rem;
  margin-bottom: 1.219rem;
}

.dummyDataLength {
  padding-left: 4px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  line-height: 120%;
  color: #898989;
}
.orgSkeleton {
  h3 {
    width: 100% !important;
  }
  li {
    width: 100% !important;
  }
}

.redAstericks {
  color: red;
}

.orgCardRow {
  margin-left: -16px;
  margin-right: -16px;
}

  .main-heading{
    display: inline-flex;
    align-items: center;
    .dumDataArr{
      font-size: 14px;
    }
  }

.arroIcn{
  display: flex;
}
@media only screen and (min-width: 1200px) {
  .marginContact {
    margin-left: 39px;
  }
}

@media only screen and (max-width: 1200px) {
  .marginContact {
    margin-right: 25px;
  }
}

@media only screen and (max-width: 1200px) {
  .emailMarg {
    margin-right: 54px;
  }
}
@media(max-width:992px){
  .orderReplace4{
    order: 4;
  }
  .orderReplace5{
    order: 5;
    padding-right:0px !important;
  }
}