.radioButton {
  margin-top: 0.938rem;
  margin-left: 1.875rem;
}

.checkboxEmail {
  position: relative;
  left: 6.25rem;
  top: 1.25rem;
}

.patientUserName {
  margin-top: -1.313rem;
}

.mrnText {
  color: #898989;
}

.dobPicker {
  box-sizing: border-box;
  border-radius: 5px;
  margin-top: 5px;
  width: 100%;
  height: 3.125rem;
}
.ant-card-body {
  height: 100% !important;
}

.cardhight {
  height: 100% !important;
}
@media(max-width:992px){
  .checkboxEmail {
    position: relative;
    left: 5.25rem !important;
    top: 1.25rem;
  }
}
.addAdmiLog{
  position: relative;
}
.addAdminItem>div>div>div{
  display: grid;
  flex-direction: row;
}
.addAdminCamera{
  position: absolute;
  bottom: 30%;
    left: 35%;
}
.addAdminItem{
  
.circleImg{
  position: relative;
  bottom: 20%;
  left: 100%;
  }
  .cameraImg{
    position: relative;
    bottom: 16%;
    left: 51%;
    z-index: 9999;
  }
}
.uploadTexts{
margin-top: 8px;
font-family: 'Open Sans';
font-style: normal;
font-weight: 400;
font-size: 10px;
line-height: 120%;
text-transform: capitalize;
color: #898989;
}
@media(min-width:840px)and (max-width:1024px){
  .addAdminCamera{
    position: absolute;
    bottom: 30%;
      left: 27%;
  }
}
@media(min-width:768px)and (max-width:839px){
  .addAdminCamera{
    position: absolute;
    bottom: 30%;
      left: 20%;
  }
}
@media(min-width:1621px)and (max-width:1800px){
  .addAdminCamera{
    position: absolute;
    bottom: 23%;
    left: 37%;
  }
}
@media(min-width:1801px){
  .addAdminCamera {
    position: absolute;
    bottom: 19%;
    left: 38%;
}
}