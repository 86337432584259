.orgNameSlicing {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;

  display: flex;
  align-items: center;
  text-align: center;

  color: #000099;
}
.orgStateSlicing {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  line-height: 200%;
  display: flex;
  align-items: center;
  color: #2e2e2e;
}

.activeAccount {
  padding-right: 5px;
}

.mobileClass{
  margin-left: 1.25rem;
  margin-right: 5px;
}
.phoneNumber{
  margin-right: 1.292rem;
  margin-left: 2px;

}
.ProvMobile{
  margin-right: 5px;
}

.phoneClass{
  margin-right: 5px;
}

.addFamilyCard ,.addProviderCard{
  min-height: 200px !important;
  height: 100% !important;
}
.mainFamilycard{
  height: 100%;
}
.familyCardDetail{
    background: #ffffff;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px !important;
    cursor: pointer;
    min-height: 200px !important;
    height: 100% !important;
    .ant-card-body{
      padding: 0px !important;
    }
    .primary-patient {
      position: relative;
      left: -15px;
    }
    .orgRow2{
      padding: 18px 1.4vw 0.3vh 1.4vw;
    }
    .orgRowpadding{
      padding: 0px 1.4vw 0 1.4vw;
    }
    .orgRow1{
      padding:  1.2vh 1.4vw 0px 1.4vw;
    }
    .orgNameSlicing{
      text-transform: capitalize;
    }
    }
    .cardPrimaryIcon{
      position: relative;
      right: -6px;
      bottom: -4px;
    }
    .cardPlistIcon{
      float: right;
      position: relative;
      top: 20px;
      right: 14px;
    }
    .orgStateSlicing{
      text-transform: capitalize;
    }
    
    
    

.menuProviderList{
  background: rgba(137, 137, 137, 0.08);
}

.addText{
  display: flex;
  align-items: center;
  text-align: center;
  font-family: Open Sans;
font-style: normal;
font-weight: normal;
line-height: 120%;
  color: #898989;
}

.addProviderCard{
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  min-height: 200px;
  padding: 10% !important;
  background: rgba(137, 137, 137, 0.1) !important;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px !important;
}

.inactiveIcon{
  margin-right: 10px;
}
.emailIcn{
  width: 13px;
  height: 11px;
}
@media(max-width:992px){
  .contactNumber{
    display: block !important;
    .mobileClass{
      margin-left: 0px !important;
    }
  }
}