.permisson-header {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px !important;
  line-height: 120%;
  letter-spacing: 0.1em;
  color: #000099;
}
.permission-description{
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  letter-spacing: 0.002em;
  color: #2E2E2E;
}
.permicheck{
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  color: #2E2E2E;
}
.permissonScreen {
  padding: 1.875rem;
  border-bottom: 1px solid rgb(240, 234, 234);
}
.permissonScreen:last-child{
  border-bottom: none
}
.radio {
  text-align: right;
}
.rolePermissionCheck {
  .ant-checkbox-disabled + span {
    color: #1c1c1c;
  }
}