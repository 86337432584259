.ib-0{
    font-family: Open Sans;
font-style: normal;
font-weight: normal;
line-height: 120% !important;
color: #161616 !important;
margin-bottom: 0px !important;
}

.ib-1{
border-radius: 5px !important;
resize: none;
&:active{
    border-color: #000 !important;
}
}
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover{
    border-color: #000 !important;
}
.ant-input-affix-wrapper-focused{
    border-color: #000 !important;
}
.ant-input:focus{
    border-color: #000 !important;
}
.ant-form-item-has-error{
    label{
        color: #EE0000 !important;
    } 
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    display: inline-block;
    margin-right: 4px;
    color: #EE0000;
    font-size: 0.83vw;
    font-family: IBM Plex Sans;
    line-height: 1;
    content: '*';
  }

  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before{
      display: none !important;
  }
  textarea.ant-input{
    min-height: 75px !important;
  }

  