.infoContainer{
    background: #FFFFFF;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 1.875rem 1.875rem 3.125rem 1.875rem;
    margin-bottom: 20px;
}
.form-container{
    margin-top: 1.875rem;
}
.contact-col{
    font-family: Open Sans;
font-style: normal;
font-weight: 600;
line-height: 120%;
color: #2E2E2E;
}
.uploadContainer{
    text-align: center;
    display: flex; 
    justify-content: center; 
    flex-direction: column;
    row-gap: 10px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    line-height: 120%;
    color: #898989;
}
.orgInfoLogo{
    margin: 0 auto;
    border-radius: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(137, 137, 137, 0.15);
    border: 1px solid rgba(137, 137, 137, 0.1);
}
.circleImg{
    position: relative;
    bottom: 5rem;
    left:2.563rem;
}
.cameraImg{
    position: relative;
    bottom: 4.875rem;
    left: 1rem;
}
.orgDetailContainer{
    padding-right: 1.563rem;
}
@media(max-width:992px){
    .cameraImg {
        position: relative;
        bottom: 5.875rem;
        left: 1rem;
    }
    .circleImg {
        position: relative;
        bottom: 6rem;
        left: 2.563rem;
    }
}