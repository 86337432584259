.nameClass{
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 120%;
    align-items: center;
    text-align: left;
    margin-left: 12px;
    color: #000099;
}

.classRole{
    font-weight: 400;
font-size: 12px;
line-height: 120%;
color: #2E2E2E;
}
@media(max-width:992px){
    .mbpadding{
        padding: 4px !important;
        text-align: center;
    }
}