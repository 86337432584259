.adversePatient{
    width: 14%;
    background: #FFFFFF;
border: 1px solid rgba(46, 46, 46, 0.2);
box-sizing: border-box;
box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
border-radius: 5px; 
}
.patientListBrdContainer{
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.patientCardContainer{
    padding-top: 10px;
    padding-right: 1.875rem;
}
.downIcon{
    padding-left: 5px;
}
.saveOrgBtn{
    background: #FFFFFF;
border: 1px solid rgba(0, 0, 0, 0.2);
box-sizing: border-box;
border-radius: 7px;
width: 100%;
height: 2.625rem;
cursor: pointer;
}
.saveOrgBtn .ant-dropdown-menu-item {
display: block !important;
}
.saveOrgBtnTxt{
    font-family: Open Sans;
font-style: normal;
font-weight: 600;
line-height: 1.188rem;
text-align: center;
letter-spacing: 0.01em;
text-transform: uppercase;

color: #2E2E2E;

}

.orgNameIcon{
    padding-right: 10px;
}

.redHeartIcon{
    padding-right: 10px;
}

.listingCheckbox{
    display: block !important; 
    flex-direction: row-reverse;
    justify-content: space-between;
    .ant-checkbox{
        float: right !important;
    }
}
.criticalityFilter{
    background: #FFFFFF;
    border: 1px solid rgba(46, 46, 46, 0.2);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    .ant-dropdown-menu-item{
        font-size: 12px;
        font-family: Open Sans;
        font-style: normal;
        line-height: 120%;
        color: #2E2E2E;
        &:hover{
            font-weight: 600;
            color: #000999 !important;
            background: #F8F8F8;
        }
    }
    .okBtn{
        margin-top: 0px !important;
        font-size: 10px !important;
        height: 2.2rem !important;
    }
}
.criticalBtn{
    font-family: 'Open Sans';
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 19px;
text-align: center;
letter-spacing: 0.01em;
text-transform: uppercase;
color: #2E2E2E
}
.criticalityFilter .listingCheckbox{
    font-size: 12px !important;
}
.search-renderer{
    float: none !important;
}
.ddlfilter{
    display: flex;
    justify-content: space-between;
    padding: 0px 10px;
}
.saveOrgBtntxtEllips div{
    width: 50%;  overflow: hidden; text-overflow: ellipsis; white-space: nowrap;
}
.saveOrgBtntxtEllips{
    display: flex; justify-content: space-around;
    font-size: 12px;
    font-weight: normal !important;
}
.okBtn{ 
border: 1px solid #000099;
box-sizing: border-box;
border-radius: 0.3rem;
text-align: center;
text-transform: uppercase;
color: #000099;
margin-top: 10px;
height: 2.3rem !important;
}
.mrgleft{
    margin-left: 30px;
}
