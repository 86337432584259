.userMenuCol{
    display: flex !important;
    font-family: Open Sans;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
line-height: normal;
padding-left: 0rem;
align-items: center;
}
.userMenu{
     align-items:center;
    cursor: pointer;
    .userDetails{
        display:flex;
        padding-left: 5px;
        text-align: left;
        width: 100% !important;
    }

}
.mainHeader .ant-dropdown-trigger{
    display: flex;
    text-align: center;
    justify-content: flex-end !important;
    width: 100%;
    padding-left: 20px;
}
.mainHeader .organizationLabel{
    width: 100%;
}
.mainHeader .orgDetailSliceCLass{
max-width: 100%;
}
.header-userName{   
font-style: normal;
font-weight: normal;
line-height: 1.188rem;
color: #2E2E2E;
margin-bottom: 2px !important;
letter-spacing: 0.05em;
max-width: 160px;
}
.header-userRole{
    font-weight: normal;
    color: rgba(46, 46, 46, 0.7);
    margin-bottom: 0px;
    text-align: left;
    margin-left: 5px;
}
.actionMenu .header{
    .ant-dropdown-menu-title-content{
        display: contents;
    }
}
.organizationLabel{
    font-family: 'Open Sans';
font-style: normal;
font-weight: 700;
letter-spacing: 0.05em;
text-transform: uppercase;
color: #2E2E2E;
display: flex;
align-items: center;
justify-content: left;
}

.orgLogoHeader{
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border-radius: 5px;
    width: 100%;
    height: 100%;
}

.userProfileHeaderName{
    width: 100%;
}

.notification{
    img{
        padding: 0px 0px;
    }
    .count{
        display: inline-block;
        padding: 0px 4px;
        color: #FFFFFF;
        font-family: Roboto;
        font-size: 12px;
        font-weight: 600;
        text-align: center;
        line-height: 1rem;
        margin:0;
        height: auto;
        width: fit-content;
        border-radius: 100%;
        background: #DC143C;
        position: relative;
        right: 12px;
        top:-10px

    }
} 
.notification{
    text-align: end;
    margin-right: 10px;
    align-items: center;
}
.notiUserMenu{
    display: flex;
    justify-content: space-around;
    padding-right: 2vw;
}

.orgLogo{
    max-width: max-content;
    min-width: auto;
    max-height: 50px;
    width: 40px;
    height: 40px;
    border: 1px solid rgba(137,137,137,0.1);
    border-radius:100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    // padding-right: 10px;
}

.orgLogo{
    img{
        width: 100%;
    max-height: 50px;
    object-fit: contain;
    }
}

.orgLab{
display: block;
}

.nameColUser{
    display: flex;
    text-align: left;
}

.logoAlign{
    display: flex !important;
    justify-content: center;
}
.customPara{
    width: fit-content;
}

.alertIcon{
    cursor: pointer;
    width: 25px;
    height: 25px;
}
@media(max-width:992px){
    .notification{
        display: flex;
        align-items: center;
        margin-right: 0px;
    }
    .notification .count {
        display: inline-block;
        padding: 0px 4px;
        color: #FFFFFF;
        font-family: Roboto;
        font-size: 12px;
        font-weight: 600;
        text-align: center;
        line-height: 1rem;
        margin: 0;
        height: 15px;
        width: -moz-fit-content;
        width: fit-content;
        border-radius: 100%;
        background: #DC143C;
        position: relative;
        right: 12px;
        top: -6px;
    }

    
}
