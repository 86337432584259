.contactCol {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #2e2e2e;
}

.uploadContainer {
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  row-gap: 10px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  line-height: 120%;
  color: #898989;
  padding-right: 20px;
  
}
.orgInfoLogo{
    margin: 0 auto;
    width: 34% !important;
    height: 6.25rem !important;
    width: 6.25rem !important;
    aspect-ratio: 1;
    border-radius: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(137, 137, 137, 0.15);
    border: 1px solid rgba(137, 137, 137, 0.1);
}
.circleImg {
  position: relative;
  bottom: 5rem;
  left: 2.563rem;
}
.cameraImg {
  position: relative;
  bottom: 4.875rem;
  left: 1rem;
}

.infoContainer {
  padding-right: 6.25rem;
}
.timeZoneRow {
  padding-top: 2.6%;
}
.brdOrganisations {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #000099;
  
}

.addOrgCardContainer{
    padding-top: 1.25rem;
}
.addOrgBrdContainer {
  padding-right: 1.25rem;
}

.asterisk {
  color: red;
}

.marginBtnOrg {
  margin-left: 0px;
}
.rowMargin {
  margin-top: 38px;
}
.infoContainerOrg {
  padding-right: 1.75rem !important;
  padding-top: 1.875rem !important;
  padding-bottom: 2.5rem !important;
  padding-left: 1.9375rem !important;
}
.ant-form-item-label > label{
  color: #161616 !important;
  font-family: 'Open Sans';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 120%;
color: #161616;
}
#organisationAdd{
  .phoneInput .ant-select-show-search.ant-select:not(.ant-select-customize-input) {
    width: 42%;
}

}