.ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  color: rgba(0, 0, 0, 0.25) !important;
  background-color: #f5f5f5 !important;
}
.dropdown {
  border: 1px solid rgba(46, 46, 46, 0.2) !important;
  box-sizing: border-box !important;
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.25)) !important;
  border-radius: 5px !important;
}

.custom {
  width: 100%;
  box-sizing: border-box !important;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;

  .ant-select-disabled.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    color: rgba(0, 0, 0, 0.25) !important;

    background-color: #f5f5f5 !important;
  }
  .ant-select-selector {
    background-color: #fefefe !important;
    height: 40px !important;
    color: #2e2e2e !important;
    border-radius: 5px !important;
    align-items: center !important;
    font-size: 12px !important;
  }
  .ant-select-selection-search-input {
    height: 40px !important;
  }
}
.options {
  background: transparent !important;
  border-left: 2px solid transparent;
  &:hover {
    background: rgba(137, 137, 137, 0.05) !important;
    border-left: 2px solid #000099;
  }
  
  &:disabled {
    opacity: 0.6;
  }
}
.options.ant-select-item-option-active{
  font-weight: 600 !important;
  border-left: 2px solid #000099;
}

@media only screen and (max-width: 998px) {
  .custom {
    .ant-select-selector {
      font-size: 1.5vw !important;
    }
  }
}
@media only screen and (max-width: 1280px) {
  .custom {
    .ant-select-selector {
      font-size: 1.25vw !important;
    }
  }
}
@media only screen and (min-width: 1660px) {
  .custom {
    .ant-select-selector {
      font-size: 0.83vw;
    }
  }
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #2e2e2e !important;
}

.ant-select:not(.ant-select-disabled):focus .ant-select-selector {
  box-shadow: none !important;
}
