.specialityCard{
    margin-top: 3vh !important;
    padding-left: 1.938rem;
    padding-top: 1.688rem;
    border-radius: 5px !important; 
}
.cardBmargin{
    margin-bottom: 0 !important;
}
.tableSpecial td{
    padding: 0px !important;
}
.tableSpecial{
    margin-top: 2.125rem;
}
.centreTable .ant-table{
    line-height: 1.3rem !important;
}
.addspeciality{
    height: auto !important;
}
.addspeciality{
        height: -webkit-fill-available;
      align-items: flex-start;
      align-items: center;
      display: flex !important;
      justify-content: space-between !important;
      grid-gap: 10px !important;
      padding: 16px 16px;
      .input-container{
        width: 100% !important;
    }
}
.centreTable{
    font-family: Open Sans;
    .selected{
        background: rgba(137, 137, 137, 0.12);
        td{
            padding:0px;
            .cellRenderer{
                // border-left: 3px solid #000099 !important;
                align-items: center;
                padding: 10px 10px 0.938rem 1.875rem;
                border: 1px solid #E9ECEF;
            }
        }
        }
    thead{
        tr{
            th{
                
                font-style: normal;
                font-weight: 600;
                font-size: 0.97vw;
                text-align: left;
                text-transform: uppercase;
                color: #000099;
                background: #F5F6FA;
                }
        }
        
    }
    .departmentTable{
        thead{
            display: none;
        }
    }
    td{
        height: 2.5rem;
        border: 1px solid rgba(137, 137, 137, 0.1);
    }
    .ant-table-expanded-row-level-1{
        td{
            // padding:0px;
            height: 2.5rem;
            border: 1px solid rgba(137, 137, 137, 0.1);
        }
    }
}