.alertContainer{
    background: #EAEEF3;
    position: absolute;
    width: 500px;
    height: 88vh;
    max-height: 800px;
    box-shadow: -3px 3px 2px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    top: 0;
    right: 0;
    max-height: 600px;
    z-index: 100;
}

.alertHead{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;    
    line-height: 160%;
    display: flex;
    align-items: center;
    letter-spacing: 0.002em;
    color: #2E2E2E;
}

.crossICon{
    float: right;
    position: relative;
    top: 9px;
    right: 12px;
    cursor: pointer;
}

.rowAlert{
    margin-top: 30px;
    padding-left: 30px;
}

.clearAll{
    font-family: 'Open Sans';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 120%;
display: flex;
align-items: center;
color: #000099;
}

.alertDivider{
    margin-left: 30px;
    margin-right: 30px;
}
.alertListContainer{
    max-height: 85%;
    overflow-y: auto;
    padding-bottom: 10px;
}
