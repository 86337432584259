.mainLayout{
   width: 100%;
   height: 100vh;
}
.mainSidebar{
    z-index: 2;
    background: #FFFFFF !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px 0px 0px 10px;
    width:13.89% !important;
    min-width: 70px !important;
}
.mainHeader{
    background: #FFFFFF !important;
    padding: 0px!important;
    width: 100%;
    z-index: 1;
    height: 85px !important;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    min-height: 60px;
    max-height: 85px;
    .flex-header{
        display: flex;
        align-items: center;
        padding-left:2vw;
        // padding-right: 2vw;
        height: 100%;
        .ant-col{
            height: auto !important;
            display: block;
            align-items: center;
            .ant-row{
                justify-content: flex-end !important;
                align-items: center;
            }
        }
    }
}
.contentHolder{
    .innerHeader{
        // height: 9.2vh;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        align-items: center;
        padding-right: 2.083vw;
        padding-left: 1.5vw;
        min-height: 70px;
        margin: 0px!important;
    }
    .listHeader{
        height: 5vh;
        align-items: center;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        margin-bottom: 2vh;
        padding-right: 2vw;
        padding-left: 3vw;
        min-height: 44px;
    }
}
.btn-saveuser,.btn-sendinvite{
    padding: 0px 15px;
}
.containerRow{
    width: 100%;
}
.collapseIcon{
    cursor: pointer;
}
@media(max-width: 1024px) {
    .expand{
        height: 16vh !important;
    }
    .mainSidebar{
        width: 15% !important;
    }
}

