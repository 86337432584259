.addRoleHeader {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #000099;
}
.saveButton {
  background: transparent;
  height: 40px;
}
.addSave{
  padding-right: 5px !important;
}
.font {
  font-size: 14px !important;
}
.addRoleRow {
  padding-bottom: 1.688rem;
  padding-inline-end: 1.75rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.card {
  border-radius: 0.938rem !important;
}