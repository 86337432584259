.rolesCardContainer{
background: #FFFFFF;
// padding-right: 1.25rem;
box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
border-radius: 10px !important;
cursor: pointer;
height: 78px;
}
.rolesTxt{
font-family: Open Sans;
font-style: normal;
font-weight: 600;
line-height: 120%;
display: flex;
align-items: center;
color: #000099 !important;
}
.rolesTxtPara{
    font-family: Open Sans;
font-style: normal;
font-weight: normal;
line-height: 160%;
display: flex;
align-items: center;
letter-spacing: 0.002em;
color: #2E2E2E !important;
}
.cardContainer{
    padding-top: 1.25rem;
}