.orgName {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #000099;
  margin-bottom: 0px;
}
.addAd{
  padding-right: 0px !important;
}
.detailsHeader {
  padding-right: 1.875rem;
  margin-bottom: 1.875rem;
  align-items: center;
}

.filter{
  justify-content: space-between;
}

.containerRow {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.organization-tabs {
  width: 100%;
  font-family: Open Sans;
  .ant-tabs-tab-btn {
    color: rgba(46, 46, 46, 0.7) !important;
    line-height: 1rem;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.1em;
    }
    .ant-tabs-tab div:hover{
      color: #000099 !important;
    }
    .ant-tabs-nav{
        padding-left: 1.5vw !important;
    }
    .ant-tabs-tab:first-child {
      border-left: 0px;
  }
  
    .ant-tabs-tab{
      border-left: 1px solid rgba(137, 137, 137, 0.3);
        margin: 0.75rem 0px 0.75rem 0px !important;
        overflow: hidden;
        justify-content: center;
        min-width: 11.8%;
        flex-shrink: 0;
        padding: 5px 5px!important;
    }
    .ant-tabs-tab-active .ant-tabs-tab-btn{
        color: #000099 !important;
        font-weight: 600;
    }
    .ant-tabs-ink-bar{
        background: #000099 !important;
    }
      .ant-tabs-nav-list{
         transform: translate(0px, 0px); 
         display: flex !important;
          width: 84%; 
          overflow-x: auto;
           
     }
    
}
.customPara{
  width: fit-content;
}

.upload{
  .material-icons-outlined{
  font-size: 20px !important;
  padding-right: 20px;
  
}}

.dobPickerOrg{
  align-items: center;
}
.organization-tabs .ant-tabs-nav-wrap{
  display: inline-block !important;
  width: "-webkit-fill-available";
}
.patient-tab .ant-tabs-nav-wrap{
  display: flex !important;
  width: '-webkit-fill-available';
}
.OrgSliceName{
  max-width: 100% !important;
  font-size: 20px !important;
}
@media(min-width:768px)and (max-width:992px){
  .ant-tabs-nav-list{
    transform: translate(0px, 0px); 
    display: flex !important;
     width: 90% !important; 
     overflow-x:auto
  }
}
.delOrg{
  .material-icons-outlined{
    width: 12px !important;
    height: 15px !important;
    margin-bottom: 4px;
  }
}
.auditDob.ant-picker{
    height: 40px !important;
  }
  .organizationAudit{
    width: 100%;
  }
  .orgAudit{
    margin-top: 0px !important;
  }
  .auditDob{
    margin-top: 0px !important;
    width: 100% !important;
  }
  .exportBtn{
    padding-right: 0px !important;
  }
.adminSave{
  padding-right: 4px !important
}
