.ant-card{
    overflow: hidden;
}
.chartWrapper{
    position: relative;
    // height: 300px;
    
    .chartplaceholder {
        position: absolute;
        left: 50%;
        top: 60%;
        width: 400px;
        transform: translate(-50%,-50%);
        img {
          width: 100%;
        }
        .text{
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            user-select: none;
            font-size: 12px;
            color: crimson;
        }
      }
}
// #glucoChartDivExpand ,#chartDivExpanded canvas{
//     width: 100% !important;
// }
.weightChart, .temperatureChart{
    .chartplaceholder{
        width: 250px;
        top: 50%;
    }
}
.reports.chartWrapper{
    height: 400px;
    overflow: hidden;
}
.adherence.chartWrapper .chartplaceholder{
    width: 600px;
    top: 40%;
}
.adherence.chartWrapper img {
    object-position: 0 -70px;
}
.adherence.chartWrapper{
    height: 300px;
    overflow: hidden;
}
.adherence.chartWrapper .chartplaceholder{
    width: 600px;
    top: 40%;
}
.adherence.chartWrapper img {
    object-position: 0 -70px;
}

.temperatureChart,.weightChart{
    position: relative;
    
}

.ant-modal-content{
    .chartplaceholder{
        width: 400px;
        margin: auto;
        top: 50%;
    }

    .chartplaceholder img{
        width: 100%;
    }
    .weightChart, .temperatureChart{
        .chartplaceholder{
            width: 280px;
        }
    }
}

