.addPatient{
    border-radius: 10px !important;
}

.userLogo{
    width:50% !important;
}
.mt20{
    margin-top: 20px;
}
.radioButton{
    margin-top: 0.938rem;
    margin-left: 1.875rem;
}

.checkboxEmail{
    position: relative;
    left: 6.25rem;
    top: 1.25rem;
}

.patientUserName{
    margin-top: -1.313rem;
}

.mrnText{
    color: #898989;
}
.ant-picker{
    font-size: 16px !important;
.dobPicker{
box-sizing: border-box;
border-radius: 5px;
margin-top: 5px;
width: 100%;
height: 3.125rem;
}
}
.gender{
    margin-left: 1.8rem;
}

#patientForm .genderCol{
    margin-left: 0px!important;
}
.btnpateintfooter{
    margin-top: 20px;
}
.rdAddpatient{
    margin-left: 40px;
}
@media (min-width:866px)and(max-width:992px){
    .rdfemale{
        margin-left: 40px !important;
        
    }
    .ant-radio-wrapper{
        margin-bottom: 10px;
    }
}
@media(max-width:889px){
    margin:0px !important;
}
@media(max-width:992px){
    .rdfemale{
        margin-left: 39px !important;
    }
    .rdAddpatient{
        margin-left: 6px !important;
       
    }
}
.addPatientUpload{
    position: absolute;
    bottom: 1%;
    left: 14%;
}