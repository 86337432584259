.patientOrgCard {
    margin-top: 1.25rem;
    padding-top: 2.25rem;
    padding-left: 2.383rem;
    height: 62.5vh;
    border-radius: 10px !important;
}

.hSOPatientDetails {
    min-height: 72vh;
    .patientOrgName {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 120%;
        letter-spacing: 0.1em;
        color: #000099;
        margin-bottom: 10px;
        text-transform: uppercase;
    }

    .ant-card-body {
        padding: 26px;
    }
    .patientOrgAddres {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
        align-items: center;
        text-align: center;
        color: #2E2E2E;
        text-align: justify;
        line-height: 1.6em;
    }

    .primeOrgDetails {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
        align-items: center;
        color: #2E2E2E;
        margin-bottom: 10px;
        display: flex;
    }
    .prime{
        margin-bottom: 15px !important;
    }
    .sb-avatar--text{
        margin-left: 20px;
    }
}

.patientOrg {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #000099;
    margin-bottom: 9px;
}

.text {
    font-weight: normal;
    display: flex;
    align-items: center;
    text-align: center;
    color: #2E2E2E;

}

.dividerCol {
    align-items: center;
    justify-items: center;
}

.divider {
    border: 1px solid rgba(0, 0, 0, 0.1);
    height: 10vh;
}
.detailPatientOrgform .divider{
    height: 18vh !important;
}
.detailPatientOrg {
    height: 10vh;
}

.prime {
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    color: #000000;
    margin-bottom: 9px;
}

.info {
    display: flex;
    align-items: center;
    text-align: center;
    margin-bottom: 0.75rem;
}

.emailI {
    margin-right: 0.813rem;
}

.primeOrgDetails {
    margin-right: 1.063rem;
}
.mobileSection{
    display: flex;
}
@media only screen and (max-width: 992px) {
    .divider {
        height: 0px !important;
    }
}
@media(max-width:992px){
    .sectionSecondary{
        margin-top: 45px;
    }
    .mobileSection{
        display: block !important;
    }
}
@media(max-width:1300px){
    .mobileSection{
        display: block !important;
    }
}
@media(max-width:1100px){
    .primeOrgDetails {
        margin-right: 0.063rem;
    }
}