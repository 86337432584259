body {
  margin: 0;
  font-family: '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue','Open Sans',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  min-width: 768px !important;
}
.font-link {
  font-family: 'Open Sans', cursive;
}
code {
  font-family: 'source-code-pro', 'Menlo, Monaco', 'Consolas', 'Courier New',
    monospace;
}
.ant-layout{
background-color: #EAEEF3 !important;
}
/* ANT-Design Overrides */

.ant-card:hover {
  box-shadow: 0px 3px 4px rgb(0 0 0 / 20%);
}
.ant-card{
  border-radius: 10px !important;
}
.ant-checkbox-checked .ant-checkbox-inner{
  background-color: #ffff !important;
}
.ant-checkbox-checked .ant-checkbox-inner::after{
  border: 3px solid #000099 !important;
  border-top: 0 !important;
  border-left: 0 !important;
}
.ant-checkbox-wrapper{
  font-size: 12px !important;
}
.ant-radio-inner::after{
  background-color: #000099 !important;
}
.ant-picker{
  height: 2.8125rem !important;
  border-radius: 5px !important;
}
.ant-picker:hover {
  border: 1px solid rgba(46, 46, 46, 0.8) !important;
  box-shadow: none !important;
}
.ant-picker-focused{
  border: 1px solid rgba(46, 46, 46, 0.8) !important;
  box-shadow: none !important;
}

.material-icons-outlined {
  font-family: 'Material Icons Outlined' !important;
  font-weight: normal !important;
  font-style: normal !important;
  font-size: 24px !important;  /* Preferred icon size */
  display: inline-block !important;
  line-height: 1 !important;
  text-transform: none !important;
  letter-spacing: normal !important;
  word-wrap: normal !important;
  white-space: nowrap !important;
  direction: ltr !important;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}
.f-14{
  font-size:14px !important;
}

.f-10{
  font-size: 10px !important;
}

.f-12{
  /* font-size: 0.83vw !important; */
  font-size: 12px;
}

.f-13{
  font-size: 13px !important;
}

.f-16{
  font-size: 16px !important ;
}

.f-18{
  font-size: 18px !important;
}

.f-20{
  font-size: 20px !important;
}

.f-21{
  font-size:21px !important;
}

.f-22{
  font-size:22px !important;
}

.f-24{
  font-size: 24px !important;
}
.h-1{
  padding: 15px !important;
}
.breadCrumbsStyle{
  margin-bottom: 10px !important;
}

@media only screen and (max-width: 998px) {
  .h-2{
    height: 15vh !important; 
  }
}
@media only screen and (max-width: 1280px) and (max-height: 816px) {
.h-2{
  height: 22vh !important; 
}
}
@media only screen and (min-width: 1660px) and (min-height: 768px) {
.h-2{
  height: 25vh; 
}
}
@media only screen and (max-width: 1366px) and (max-height: 633px) {
  .h-2{
    height: 25vh; 
  }
  }

@media only screen and (max-width: 998px) {
    .f-14{
      font-size: 15px !important;
    }
}
@media only screen and (max-width: 1280px) {
  .f-14{
    font-size: 14px !important;
  }
}
@media only screen and (min-width: 1660px) {
  .f-14{
    font-size: 14px !important;
  }
}

@media only screen and (max-width: 998px) {
  .f-10{
    font-size: 16px !important;
  }
}
@media only screen and (max-width: 1280px) {
.f-10{
  font-size: 10px !important;
}
}
@media only screen and (min-width: 1660px) {
.f-10{
  font-size: 10px;
}
}

@media only screen and (max-width: 998px) {
  .f-12{
    font-size: 12px!important;
  }
}
@media only screen and (max-width: 1280px) {
.f-12{
  font-size: 12px !important;
}
}
@media only screen and (min-width: 1660px) {
.f-12{
  font-size: 12px;
}
}

@media only screen and (max-width: 998px) {
  .f-20{
    font-size: 20px !important;
  }
}
@media only screen and (max-width: 1280px) {
.f-20{
  font-size: 20 !important;
}
}
@media only screen and (min-width: 1660px) {
.f-20{
  font-size: 20px;
}
}


@media only screen and (max-width: 998px) {
  .f-16{
    font-size: 16px!important;
  }
}
@media only screen and (max-width: 1280px) {
.f-16{
  font-size: 16 !important;
}
}
@media only screen and (min-width: 1660px) {
.f-16{
  font-size: 21px
}
}

@media only screen and (max-width: 998px) {
  .f-18{
    font-size: 18px !important;
  }
}
@media only screen and (max-width: 1280px) {
.f-18{
  font-size: 18px!important;
}
}
@media only screen and (min-width: 1660px) {
.f-18{
  font-size:18px
}
}