.orgNameSlicing {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  display: flex;
  align-items: center;
  text-align: center;
  margin-bottom: 0;
  color: #000099;
  margin-bottom: 10px;
}
.orgStateSlicing {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  line-height: 200%;
  display: flex;
  align-items: center;
  color: #2e2e2e;
  margin-bottom: 0;
}

.cardPhoneIcon{
  padding-right: 8px;
}


.orgMenuItem{
  background: #FFFFFF;
border: 1px solid rgba(151, 151, 151, 0.3);
box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
border-radius: 3px;
}
.activeAccount {
  padding-right: 5px;
}

.orgCardMatrix {
  background: #ffffff;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px !important;
  cursor: pointer ;
  min-height: 200px;
  padding: 0px!important;
  .ant-card-body{
    padding: 0px;
  }
  }

  .orgRow2{
    padding: 0px 1.25rem 1.875rem 1.25rem;
  }
  
  .orgRow1{
    padding: 1.25rem 1.25rem 0px 1.25rem;
  }

  .orgColClass{
    display: flex;
    justify-content: right;
    text-align: right;
  }

  .menuClass{
    margin-left: 5px;
  }
  @media(max-width:1024px){
    .orgCardMatrix{
      .orgStatusMargin {
        margin-left: 0px;
    }
    }
  }
  .ddlOrgViewcard{
    float: right;
    position: relative;
    z-index: 9999;
    height: 2rem;
    width: 3rem;
    top: 5px;
    text-align: center;
  }
  .orgCardMatrix{
    .orgRow2{
      margin-top: 32px;
    }
  }