.scheduleTable {
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px !important;

    .patientShedules {
        padding-left: 0px !important;
    }
}

.ddlschedule .ant-dropdown-menu-item {
    display: block !important;

}

.patientShedules {
    padding-left: 20px;
}


.colpatientShedule {
    padding-right: 10px;
}

.txtPatientSchedule ,#scheduleUpdate {
    .custom {
        background-color: #fefefe !important;
        height: 40px !important;
        color: #2E2E2E !important;
        border-radius: 5px !important;
        align-items: center !important;
        font-size: 12px !important;
        margin-top: 4px;
    }

    .ant-select-selector {
        height: 40px !important;
        margin-top: 0px !important;
    }

    .addButton {
        margin-top: 1.6rem;
        height: 40px;
    }

    .ant-picker {
        display: flex !important;
    }
    .ant-btn-sm{
        height: auto !important;
    }
    .ant-picker{
        height: 40px !important;
        margin-top: 0px !important;
    }
    

}
.ant-cascader-menu-item-content {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 120%;
    color: #2E2E2E !important;
}
.ant-btn-sm ,.ant-picker-now-btn{
    height: auto !important;
border: 1px solid #000099 !important;
border-radius: 5px !important;
background-color: #FFFFFF !important;
color: #000099 !important;
font-weight: 700 !important;
padding: 4px 11px !important
}
.ant-picker-now-btn{
    padding: 6px 11px !important;
}
.infoToolTip .ant-tooltip-inner{
    
    font-size: 12px !important;
}
.txtRecurrence {
    .custom {
        margin-top: 0px !important;
    }
}
.sheduleTable {
    .ant-table-tbody>tr>td {
        border-bottom: 1px solid #f0f0f0;
        transition: background 0.3s;
        padding: 5px 10px !important;
    }
  
}

.tableSpecial .instructionTable,
.tableSpecial .deleteIcon {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px !important;
    line-height: 120%;
    padding: 16px !important;
}
.tableSpecial table thead th{
    font-weight: 600 !important;
}
.providerButton {
    padding-left: 20px;
}

.chekbox {
    justify-self: end;
}
.patientShedule{
    .textFont{
        font-family: 'Open Sans';
font-style: normal;
font-weight: 600 !important;
font-size: 16px !important;
letter-spacing: 0.05em;
text-transform: uppercase;

color: #2E2E2E;
    }
}
.textFont {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 16px !important;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.01em;
    text-transform: uppercase
}

.dobText {
    font-size: 12px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 120%;
    color: #161616;

}

.buttonSchedule {
    width: 100%;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    height: 2.5rem;
    justify-content: space-around;
    align-items: center;
    display: flex;
    .provFilter {
        width: 15px;
        height: 15px;
        margin-right: 5px;
    }
    .provImg{
        width: 12px;
        height: 9px;
    }
    .textFont{
        font-size: 14px !important;
    }
}
.scheduleMonth .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner{
    border-radius: 0 !important;
    border-color:0px !important; 
    background-color: #DAECFA !important;
    border-color: #DAECFA !important;
   
}
.scheduleMonth {
    .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner, .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner{
     background: #f5f5f5;
     border-radius: 0 !important;
    }
  
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
        padding: 4px 8px 4px !important;
}
 .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
        height: 100px !important;
    }
.scheduleMonth .ant-picker-calendar-date-value {
    text-align: left;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 120%;
    text-transform: uppercase;
    color: #2E2E2E !important;
}
.scheduleViewDay{
    .readingLabel {
        width: 40px;
        height: 40px;
        margin-bottom: 5px;
        img{
        width: 23px;
        height: 20px;
}
    } 
}
.scheduleViewWeek{
    .readingLabel {
        width: 35px !important;
        height: 35px !important;
        margin-bottom: 5px;
        img{
        width: 22px;
        height: 20px;
}
    } 
    .ant-table-body{
        overflow-x: hidden !important;
    }
}
.scheduleMonth{
    .readingLabel {
        width: 30px;
        height: 30px;
        margin-bottom: 5px;
        img{
        width: 20px;
        height: 16px;
}
    }
    table{
        thead{
            tr{
             
              th{
                color: #000099 !important;
              }
            }
        }
    }

}

.colClass {
    justify-content: center !important;
    text-align: center !important;
    display: flex !important;
    align-items: center !important;
    font-size: 14px !important;
}

@media(min-width:768px) {
    .colClass {
        margin-top: 8px;
        margin-bottom: 8px;
    }
}

.addButton {
    margin-top: 1.938rem;
}

.menu-itemCheckbox {
    display: block !important;
    flex-direction: row-reverse;
    justify-content: space-between;

    .ant-checkbox {
        float: right !important;
    }
}

.schIcon {
    cursor: pointer;
    font-size: 25px !important;
    color: rgba(137, 137, 137, 0.8) !important;
}
// add css for calender
.ant-picker-header-view {
    color: #0E3077 !important;
    font-weight: 600 !important;
    font-size: 14px;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    border: 1px solid #000099 !important;
    border-radius: 50% !important;
    content: '';
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    color: #fff;
    background: #000099 !important;
    border-radius: 50% !important;
}
.ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner, .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
    background: #f5f5f5;
    border-radius: 50% !important;
}

.ant-picker-cell .ant-picker-cell-inner {
    position: relative;
    z-index: 2;
    display: inline-block;
    height: 24px;
    line-height: 24px;
    border-radius: 2px;
    transition:none !important; 
}
.ant-picker-cell-in-view:after{
    color: #fff !important 
}
.ant-picker-cell .ant-picker-cell-inner{
    font-size: 12px;
  }
  .ant-picker-date-panel .ant-picker-content{
      width: 100% !important; 
  }
  .ant-picker-content{
      tr{
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.4px;
        text-transform: uppercase;
        color: #838383;
      }
      td{
          font-size: 12px;
         
      }

  }
  .ant-picker-time-panel-column {
    width: 65px !important;

}
.schbttun{
    margin-inline-start: auto;
}
.infoIconDiv{
    position: relative;
    left: 9rem;
    top:1.6rem;
    color: #000099;
    width: fit-content;
    z-index: 1000;
    cursor: pointer;
}
.infoIcon{
    font-size: 20px !important;
}
@media only screen and (max-width: 1280px){ 
.custom .ant-select-selector {
    font-size: 12px !important;
}
}
@media(max-width:992px){
    .filtersection{
        justify-content: end;
    margin-top: 15px;
    }

}
.scheduleMonth{
    .ant-picker-cell .ant-picker-cell-inner{
        z-index: 0 !important;
    }
}
.PatientSheduleMbView ::-webkit-scrollbar{
    height:6px;
}
.ant-table-expanded-row-fixed{
    width: 100% !important;
}