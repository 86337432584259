.userListCard {
    background: #FFFFFF !important;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 0.938rem !important;    
    border-radius: 10px !important;
    .ant-card-body{
      padding: 0px !important;
    }
    cursor: pointer;
 &:hover{
        box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.2);
    }  
  }
  .menuList{
    background: #FFFFFF;
    border: 1px solid rgba(151, 151, 151, 0.3);
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
  }
  .red{
    margin-right: 0.516rem;
  }
  .resetPass{
    margin-right: 8.1.563rem;
    margin-bottom: 4.1.563rem;
  }
  .hand{
    margin-right: 2px;
  }

  .userName{                                              
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    display: flex;
    align-items: center;
    color: #000099;
    margin-left:  10px;
    
  }
.border{
  border-top:1px solid #f0f0f0
}
   .userButton{
     text-align: center;     
  }
  .userStatus{
     justify-content: right;
   }
.userRow1{
  padding: 0.875rem 0px 0.875rem 1.313rem;
}
.userRow2{
  padding: 0.42rem 1.313rem 0.875rem 0px;
}
.userRow2 img{
  padding: 5px;
}  
  .userCard{
      align-items: center;
      font-family: Open Sans;
      font-style: normal;
      display: flex;
      line-height: 120%;
      font-weight: normal;
      color: #2E2E2E; 
  }
  .userProfile{
    text-align: left ;
    margin-left:  12px;
  }
  .Green-button{
    padding-right: 5px;
  }
  .ant-dropdown-menu-item{
   font-size: 12px !important;
  }
  .ant-dropdown-menu{
    padding-top: 6px !important;
    padding-bottom: 6px !important;
  }
@media (min-width: 1200px) {
.userMob{
  margin-left: 38px!important;
}
.userCiti{
  margin-left: 15px !important;
}
.userEmail{
  margin-left:9px !important
}
.userRole{
  margin-left: 11px;
}
}
@media (min-width: 992px) and (max-width: 1199px){
  .userRole{
    margin-left:25px
  }
}
@media only screen and (max-width: 768px) {
  .userName{
      padding-left: 5px;
  }
}
.activeDropDown{
  margin-left: 6px;
}