.emailCheckbox{
    position: relative;
    left: 6.25rem;
    top:1.438rem;
}

.addUserForm{
    border-radius: 10px;
    padding-top: 1.25rem;
    .ant-card{ min-height: 72vh;
    }}

    @media(max-width:992px){
        .emailCheckbox{
            position: relative;
            left: 5.25rem;
            top:1.438rem;
        }
    }
   

