.payerListCard{background: #FFFFFF !important;
box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
margin-bottom: 0.938rem !important;    
border-radius: 10px !important;
.ant-card-body{
  padding: 0px !important;
}
cursor: pointer;
&:hover{
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.2);
}  
}
.menuItemStyling{
    height: 3.7vh;
}
.menuStyling{
    padding: 1.15vh 1.076vw 1.05vh 1.076vw ;
}
.edIcon{
    color: #898989;
}
.deIcon{
    color: #898989;
}
.addPayer{
    min-height: 72vh;
}
.payerListCard{
    .ddlpayor{
        ul{
            li{
                border-bottom:1px solid #ddd;
            }
            li:last-child{
                border-bottom:0px
            }
        }
    }
}
