.weightIndication{
    border-bottom: 1px solid rgba(137, 137, 137, 0.2);
    margin-bottom: 15px;
    
}
.boredrRight,.boredrRight1{
border-right: 1px solid rgba(137, 137, 137, 0.2);
}

@media(max-width:992px){
    .boredrRight{
        border-right:0px
        } 
        .colAdherence{
            border-top: 1px solid rgba(137, 137, 137, 0.2);
        }
}