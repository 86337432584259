.cardRow{
    margin-top: 0.875rem;
}

.adherenceSummary{
font-family: 'Open Sans';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 120%;
padding-left: 10px;
display: flex;
align-items: center;

}
.summaryAdherencePage{
color: #000099;
padding-left: 10px;
display: flex !important;
align-items: center;
span{
    margin-left: 10px !important;
}
}
.summaryRow{
    padding-left: 20px;
}

.artIcon{
    color: #fff;
}

.articleIcon{
    height: auto;
    width: 40px;
    aspect-ratio: 1;
    border-radius: 50%;
    display: flex;
    background-color: #000099;

    justify-content: center;
    align-items: center;
}

