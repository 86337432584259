.primary {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 0.97vw;
  line-height: 1.188rem;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  border-radius: 5px;
  color: #000099;
  height: 2.5rem;
  width: 100%;
  border: 1px solid #000099;
  cursor: pointer;
  background-color: transparent;
  &:hover {
    background: #3d1fa7;
    border: 1px solid #7951c4;
    box-sizing: border-box;
    box-shadow: 0px 1px 2px 0.5px rgba(61, 31, 167, 0.8);
    color: #ffffff;
  }
  &:active {
    background: #7951c4;
    border: 1px solid #5d38b6;
    box-sizing: border-box;
    box-shadow: 0px 1px 3px 0.5px rgba(61, 31, 167, 0.8);
    color: #ffffff;
  }
  &:disabled {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    background: #eaeef3;
    border: 1px solid #bdeaff;
    box-sizing: border-box;
    box-shadow: 0px 1px 2px 0.5px rgba(224, 236, 248, 0.7);
    color: #000099;
  }
  .material-icons-outlined{
    font-size: 20px !important;
    margin-right: 8px !important;
    width: 18px;
    height: 18px;
  }
}

.secondary {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size:  14px !important;
  line-height: 1.188rem;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #000099;
  height: 2.5rem;
  width: 100%;
  border: 1px solid #000099;
  border-radius: 5px;
padding: 0px 10px;

  cursor: pointer;
  &:hover {
    border: 1px solid #7951c4;
    box-sizing: border-box;
    color: #3d1fa7;
  }
  &:active {
    border: 1px solid #5d38b6;
    color: #7951c4;
  }
  &:disabled {
    background: #ffffff;
    border: 1px solid #e5edf5;
    box-sizing: border-box;
    color: #000099;
    font-weight: normal;
  }
}
.tertiary {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 0.90vw;
  line-height: 1.125rem;
  text-align: center;
  letter-spacing: 0.01em;
  color: #000099;
  background-color: transparent;
  border: 0px;
  border-radius: 5px;
  width: 100%;

  cursor: pointer;
  &:hover {
    color: #3d1fa7;
  }
  &:active {
    border-radius: 10px;
    color: #7951c4;
  }
  &:disabled {
    color: #8c8c8c;
  }
}
@media only screen and (max-width: 998px) {
  .primary, .secondary, .tertiary{
    font-size:  10px!important;
  }
}
@media only screen and (max-width: 1280px) {
  .primary, .secondary, .tertiary{
  font-size: 10px !important;
}
}
@media only screen and (min-width: 1660px) {
  .primary, .secondary, .tertiary{
  font-size: 14px ;
}
}
