.spiroIcon{
    position: relative;
    left: 1.875rem;
}
.patientCard-header{
    padding-right: 1.875rem;
}
.bpMenu{
    padding-left: 10px !important;
}
.bpMenu {
    li{
        border-right: 1px solid rgba(137, 137, 137, 0.2);
    }
    .brNone{
        border-right:none !important;
      }
    .menuItemText{
        font-family: Open Sans;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        color: #626262;
        font-size: 14px;
    }
    .ant-menu-item-selected{
    .menuItemText{
        color: #000099 !important;  
        font-weight: 600;
    }
}
.ant-menu-item:hover{
    color: #626262 !important;
    font-weight: 600;

}
.ant-menu-item-selected::after{
    border: none !important;
}
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected{
        color: #000099 !important;
    }
      .ant-menu-item-selected > .bpMenuLi {
    color: #000099 !important;
        }

}
.dotCircleIcon{
    padding-right: 5px;
}
.bpMenutab{
    display: flex;
    justify-content: left;
    align-items: center;

}
li:hover{
    .bpMenutab:hover{
        .readingLabel{
            background-color: #000099;
            
        }
        .menuItemText{
            color: #000099;
            font-weight: 600;
        }
        }
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color:#F4F4F4 !important
}
.patinetMenu{
 padding: 4px;
    align-items: center;
    .bpPatienttab li{
        min-width: 12.9%;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px 14px;
        text-align: center;
    }
  
}

.bpMenutab >.readingLabel {
    height: 35px;
    width: 35px!important;
    aspect-ratio: 1;
    border-radius: 50%;
    display: inline-flex;
    background-color: grey;
    margin-right: 5px;
    justify-content: center;
    align-items: center;
}

.bpMenutab{
    .gluco img{
        width: 20px;
        height: 16px !important;
    }
    .bpIcon img{
width:23px !important;
height:20px!important;
    }
    .spo2Icon img{
        width: 32px;
        height: 16px !important;
    }
    .Spirometer img{
    width: 22px;
    height: 20px !important;
    }
}
.ant-menu-item-selected .bpMenutab .readingLabel{
    
        background-color: #000099 !important;
    }
.bpMenu .ant-menu-item-selected .readingLabel{
    background-color:#009;
}
.bpMenu .ant-menu-item-selected .readingLabel:hover{
    background-color: #009;

}
.bpMenu .ant-menu-item-selected .menuItemText:hover{
    color: #000099 !important;
}
.patinetMenu{
    li.ant-menu-overflow-item.ant-menu-item.ant-menu-item-only-child span.ant-menu-title-content{
        margin-bottom: 12px;
    }
    li.ant-menu-overflow-item.ant-menu-item.ant-menu-item-only-child{
        border-bottom: 2px solid #f0f0f0
    }
    .ant-menu-horizontal > .ant-menu-item::after, .ant-menu-horizontal > .ant-menu-submenu::after{
        border-bottom: 0px!important;
    }
    
      .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
        color: rgb(0, 0, 153)!important;
        border-bottom: 2px solid rgb(0, 0, 153)!important; ;
    }
    
    .bpPatienttab li:last-child {
        border-right:none !important;
      }
}

@media(max-width:992px){
    .patinetMenu{
        .ant-menu-item .ant-menu-item-icon, .ant-menu-item .anticon, .ant-menu-submenu-title .ant-menu-item-icon, .ant-menu-submenu-title .anticon {
            min-width: 20px !important;
            font-size: 30px !important;
            transition: font-size .15s cubic-bezier(.215,.61,.355,1),margin .3s cubic-bezier(.645,.045,.355,1),color .3s;
        }
        .patinetMenu .ant-menu-submenu-title{
           line-height: 1.6rem;
        }
    }
       
}


