.organisationLogoPic .profileThumb , .userProfileIcon .profileThumb{
    width: 40px !important;
    height: 40px !important;
    aspect-ratio: 1;
    img{
        object-fit: cover !important;
    }   
}
.sb-avatar__text{
    font-weight: 600;
    font-family: 'Open Sans';
}
.uploadContainer{
    cursor: pointer;
    .profileThumb{
        height:100px !important;
        img{
            margin: 0 auto;
            width: 34% !important;
            height: 6.25rem !important;
            width: 6.25rem !important;
            aspect-ratio: 1;
            border-radius: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background: rgba(137, 137, 137, 0.15);
            border: 1px solid rgba(137, 137, 137, 0.1);
        }
        
    }
}

.ProPatientDetails ,.hSOPatientDetails ,.summaryCard{
    .profileThumb{
        width: 110px !important;
        height: 110px !important;
        margin-right: 20px;
        img{
            margin: 0 auto;
            width: 34% !important;
            height: 6.25rem !important;
            width: 6.25rem !important;
            aspect-ratio: 1;
            border-radius: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background: rgba(137, 137, 137, 0.15);
            border: 1px solid rgba(137, 137, 137, 0.1);
        }
    }
}
.patientDetailsCard{
    .profileThumb{
        width: 40px !important;
        height: 40px !important;
    }
}
@media only screen and(min-width:1880px) and (max-width:2560px){
    .adherentIcon {
        position: relative;
        bottom: -1.062rem !important;
        left: -1.4rem !important;
    }
}

.profileThumb{
    img{
        object-fit: cover !important;
    }
}