.scrollContent {
    max-height: 100%;
    overflow-y: auto;
    padding-right: 2vw;
    padding-left: 1.5vw;
    width: 100%;
    overflow-x: hidden;
    padding-bottom: 20px;
  }
  .scrollStyle::-webkit-scrollbar {
    width: 5px;
  }
    
    /* Track */
  .scrollStyle::-webkit-scrollbar-track {
    border-radius: 10px;
    background: #9e9e9e71;
  }
     
    /* Handle */
  .scrollStyle::-webkit-scrollbar-thumb {
    background: #000999; 
    border-radius: 5px;
  }
    
    /* Handle on hover */
  .scrollStyle::-webkit-scrollbar-thumb:hover {
    background:  #000999;  
  }
  .scrollStyle::-webkit-scrollbar-thumb {
    background: #000999 !important;
    border-radius:5px !important
;
}
  
  
  