.txtNoti{
font-family: 'Open Sans';
font-style: normal;
font-weight: 600;
font-size: 16px !important;
line-height: 120%;
letter-spacing: 0.05em;
text-transform: uppercase;
color: #2E2E2E;
}
.btnRedo{
    padding-right: 52.5px!important;
}
.txtnme{
font-family: 'Open Sans';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 19px;
text-align: center;
letter-spacing: 0.01em;
text-transform: uppercase;
color: #2E2E2E;
}
.txtDate{
font-family: 'Open Sans';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 120%;
color: #161616;
margin-bottom: 6px;
}
.tableNoti{
    margin-top: 33px;
}
.notificationCard{
    margin-left: 32px !important;
    margin-right: 32px !important;
    margin-top: 15px !important;
    margin-bottom: 27px !important;
    height: 100%;
    min-height: 82.8vh;
    .ant-picker { height: 2.5rem !important; border-radius: 5px !important; }
    .primary{
        width: 40px !important;
    }
}
.notificationCard{
    .dobPicker{
        height: 40px!important;
        border-radius: 5px!important;
        margin-top: 0px !important;
        .ant-picker-input > input{
            font-size: 12px;
        }
    }
}

.notifibtn{
    margin-top: 5px;
}
