.platformUsers{
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #000099;
}
.orgReset{
    margin-bottom:3px
}
.searchIcon{
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    padding-top: 0.813rem;
    padding-bottom: 0.813rem;
}
.btnIntruction{
    font-size: 14px !important;
    font-weight: 600 !important;
}
.cityState{
    text-align: center;
}
.filter{
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
}
 
.filterIcon{
    margin-right: 0.219rem;
}

.addIcon{
    margin-right: 5px;
}

.addUser{
    font-family: Open Sans;
font-style: normal;
font-weight: bold;
line-height: 1.188rem;
display: flex;
align-items: center;
text-align: center;
letter-spacing: 0.05em;
text-transform: uppercase;
color: #000099;
}
.noData{
    text-align: center;
}
.redoIcon{
    margin-right: 9px;
}
.upIcon{
    margin-left: 5px; 
}
.platformUserBrd{
    padding-right: 1.563rem;
}
.userFilter{
   text-align: center;
 }
.userListRow{
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    height: 3.438rem;
    font-family: Open Sans;
font-style: normal;
font-weight: 600;
font-size: 0.83vw;
line-height: 120%;
display: flex;
align-items: center;
text-transform: uppercase;
padding-right: 4%;
color: #2E2E2E;
    margin-top: 1.563rem;
    margin-bottom: 1.219rem;
}
.dumDataLength{
    font-family: Open Sans;
    font-style: normal;
font-weight: normal;
line-height: 120%;
color: #898989;
}
.statusFilter{
    text-align: center;
}
.dumDataArr{
    color: #000099;
}
.emailFilter{
    padding-left: 1.875rem;
}
.alignClass{
   text-align: center;
}
.marginUserList{
    margin-left: 10px;
}
@media only screen and (max-width: 990px) {
    .marginContactnum{
        margin-left: -43px;
    }
    }    
    @media only screen and (max-width: 1200px) {
        .marginRole{
            margin-left: 35px;
        }
        }
        