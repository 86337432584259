.ant-select-item-option-state{
      display: none;
  }

//   .ant-checkbox{
//       display: none;
//   }

  .ant-select-selection-item{
      .checkbox{
          .ant-checkbox-inner{
            display: none;
            
          }
      }
  }
.multiselect{
.ant-select-item-option-content{
    display: block !important;
      flex-direction: row-reverse;
      justify-content: space-between;
      .ant-checkbox{
        float: right !important;
    }
}
  }
  .checkbox {  
    float: right !important;
}