.rest-message{
    font-family: Open Sans;
font-style: normal;
font-weight: 600;
font-size: 1.25rem;
line-height: 120%;
letter-spacing: 0.02em;
color: #000099;
display: flex;
text-align: center;
}

.email-row{
    margin-top: 20px;
}

.resentLink{
    margin-right: 8px;
}

.reset-link{
    background: transparent;
}

.resetModal{
    .ant-modal-content{
        display: flex;
        align-items: center;
        border-radius: 10px;
        justify-content: center;
    }
    
    .ant-modal-body{
        min-height: 40vh;
        width: 50vw;
        padding: 11.2vh 5.13vw 11.2vh 5.13vw;
    }
   
}