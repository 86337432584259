.mainPage {
  background-color: lavender;
  height: 100vh;
}

.change {
  .ant-card-body {
    padding-top: 4.188rem !important;
    padding-right: 4.625rem !important;
    padding-left: 4.688rem !important;
    padding-bottom: 4.206rem !important;
  }
}

.forget {
  color: #000099;
  text-align: center;
  margin-bottom: 1.063rem;
  margin-top: 0;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: 0.1em;
}

.text2 {
  color: #2e2e2e;
  text-align: center;
  margin-bottom: 0;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  line-height: 120%;
  margin-bottom: 1.618rem;
  letter-spacing: 0.05em;
}

.email {
  margin-bottom: 1.688rem;
}

.icon {
  padding-right: 0.406rem;
}