.patientRowCardList{
    background: #FFFFFF;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
border-radius: 10px !important;
margin-bottom: 0.75rem !important;
margin-top: 0.75rem !important;
.readingStatus{
    justify-content:center;
    flex-direction: column;
    border-left: 1px solid rgba(0, 0, 0, 0.05);  
}
.pro{
position: relative;
top: 10px;
left: 0px;
}
cursor: pointer;
}
.dot{
    height: 2.5rem;
  width: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #bbb;
  border-radius: 50%;
  margin-inline-start: 35%;

  
}
.crtClr{
    background-color: #DC143C;
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-inline-start: 35%;
    
}
.birtDate{
    font-family: PT Sans;
font-style: normal;
font-weight: normal;
line-height: 120%;
display: flex;
align-items: center;
letter-spacing: 0.05em;

color: #2E2E2E;

}
.noteBookIcon{
    padding-right: 10px;
}
.dob{
    margin-top: 2px;
    font-family: PT Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px !important;
    line-height: 120%;
    display: flex;
    align-items: center;
    letter-spacing: 0.05em;
    color: #898989 !important;

}

.ptName{
    font-family: Open Sans;
font-style: normal;
font-weight: 600;
font-size:16px !important;
line-height: 120%;
margin-bottom: 3px;
display: inline-block;

color: #000099 !important;
}
.bpIcon{
    position: relative;
    top: "8px" ;
    left: "2px";
}
.noteBookBtn{
    cursor: pointer;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    height: 40px;
}
.daymeasures{
    text-align: center !important;
}
.noteBookIcon{
    padding-right: 3px;
}
.ellipsMenu{
    background: #FFFFFF;
border: 1px solid rgba(46, 46, 46, 0.2);
box-sizing: border-box;
box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
border-radius: 5px;
}

.primaryIcon{
    position: relative;
    right: 0.75rem;
    bottom: 0.938rem;
}
.adherentIcon{
    position: relative;
    bottom: 0.938rem;
    left: 1.6rem
}
.dayLabels{
    span{
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 0.83vw;
    line-height: 122%;
    color: rgba(46, 46, 46, 0.7);
}
}

.daysLabel{
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 122%;
    color: rgba(46, 46, 46, 0.7); 
    text-align: center;
    padding-top: 10px
}
.daysLabel span{
    display: inline-block;
    margin-right: 8px;
}

.adherence{
    margin-top: 5px;
    font-family: Open Sans;
font-style: normal;
font-weight: 600;
font-size: 12px;
line-height: 122%;
color: rgba(46, 46, 46, 0.7); 
text-align: center;

}
.patientMenuIcon{
    margin-right: 0.813rem;
}
.adherence span{
    margin-right: 8px;
}
.patientIcon{
    width: 100% !important;
    height: auto !important;
    aspect-ratio: 1;
    object-fit: cover;
    box-shadow: rgb(0 0 0 / 9%) 0px 4.75rem 3.75rem, rgb(0 0 0 / 6%) 0px 31.751px 25.0666px, rgb(0 0 0 / 6%) 0px 16.9756px 13.401.125rem, rgb(0 0 0 / 4%) 0px 9.51638px 7.51293px, rgb(0 0 0 / 4%) 0px 5.05408px 3.99006px, rgb(0 0 0 / 2%) 0px 2.10311px 1.66035px !important; 
}
.patientDropdown{
    position: relative;
    bottom: 28px;
}
.patientRowCardList .ant-card-body img.primaryIcon{
    position: relative; top: 5px; left: -7px;
}
.patientRowCardBody2 .ant-card-body{
    padding:16px 15px 16px 15px!important;
}
.patientDetails{
    .ptName{
        margin-bottom: 0px !important;
        width: 80%;
    }
}
.plistColumn2{
  float: right;
  position: relative;
  top: 16px;
}
.patientRowCardBody2 .plistColumn2{
    float: right;
    position: relative;
    top: 0px !important;
}
.patientRowCardBody1 .ant-card-body{
    padding:0px 33px 16px 13px!important;
}
.isprimaryplistCoulumn2{
    left: 18px;
}
.ptntRowCritical{
    .patientDetails{
        padding-left: 10px;
    }
}
@media screen and (max-width: 1200px) {
    .patientIcon {
        width: 2.77vw !important;
        }
      }
@media screen and (max-width: 1200px) {
    .patientIcon {
        width: 2.77vw !important;
        }
      }
      @media screen and (max-width: 991px) {
        .patientIcon {
            width: 4.77vw !important;
            }
            .patientRowCardList .patientDetails{
                margin-left: 30px !important;
            }
          }
       
          @media screen and(max-width:768px){
            .patientDetails{
                margin-bottom: 10px;
            }
              
          }
@media(max-width:1024px){
    .patientDetails{
        margin-left: 12px;
    }
}
@media only screen and (max-width: 1217px){
.birtDate {
    font-size: 10px !important;
}
}