.glucoTxt{font-family: Open Sans;
font-style: normal;
font-weight: 600;
line-height: 180%;
color: #2E2E2E;}
.glucoNmbr{
    font-family: Open Sans;
font-style: normal;
font-weight: normal;
line-height: 180%;
color: rgba(46, 46, 46, 0.8);
}