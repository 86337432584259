.selectPlayerRow{
    padding-top: 3%;
}
.payerSearch{
    width: 50%;
    height: 100%;
    background: #ffff !important;
    border:1px solid #000099;
    border-radius: 5px;
}
.addFamilyBtn{
    padding-top: 3%;
}
.addPayerButton{
    // border: none;
    // background: #F5F6FA;
    // color: #2E2E2E;
    // font-weight: 700;
    margin-left: 25px;
}
.payerTable{
    min-height: 72vh;
    table{
        thead{
            tr{
                th{
                    text-align: center;
                }
            }
        }
        tbody{
            tr{
                td{
                    text-align: center;
                }
            }
        }
    }
}