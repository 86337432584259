.styleColor{
    color: #2E2E2E;
}

.providerRow{
    display: flex;
    justify-content: space-between;
}

.menuFilter{
    text-align: right;
    margin-right: 3px;
}
.borderBottom{
    border-bottom: 1px solid #f0f0f0;
}
.addUserBtn{
    margin-left: -8px;
}
.btnW{
    width: 110%;
}
.dropImg{
    width: 12px;
    height: 9px;
}

.orgRoleBtn{
    display: flex;
    justify-content: space-between;
    width: -webkit-fill-available;
}

.activeBtn{
    justify-content: space-between;
    p{
        margin-bottom: 0px !important;
    }
}
.userBrd{
    .ant-breadcrumb{
        margin-top: 10px !important;
    }
}
li.ant-dropdown-menu-item-divider:last-child {
    background: none;
}
