.terms{
    font-size: 14px;
}

.services{
    font-size: 12px;
}

.headingTerms{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.termsOfService{
    max-height: 85% !important;
    overflow-y: auto !important;
    padding-bottom: 10px !important;
}

.termsModal,.termsOfService{
    .ant-modal-body{
        height: 664px;
        overflow-y: auto;
    }
    .ant-modal-title{
        font-size: 20px;
        font-weight: 600;
        text-underline-position: under;
        display: flex;
        justify-content: center;
        color:#2E2E2E;
        font-size: 16px;
    }
    .privacyPolicy {
        b{
            font-size: 14px;
        }
        p{
            font-size: 12px;
        }
    }
}
.termsModal>div{
    width: 700px !important;

}
.noteTerm{
    color: red;
}

