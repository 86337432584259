.pass-message{
    display: flex;
    text-align: center;
}

.pass-text{
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 120%;
    letter-spacing: 0.02em;
    color: #000099;
}

.card-modal{
    background: rgba(137, 137, 137, 0.05) !important;
border: 1px solid rgba(137, 137, 137, 0.15) !important;
padding: 20px 20px 20px 20px;
}

.copyRow{
display: flex;
text-align: left;

}

.passwordModal{
    .ant-modal-content{
        display: flex;
        align-items: center;
        border-radius: 10px;
        justify-content: center;
    }
    
    .ant-modal-body{
        // height: 40vh;
        width: 50vw;
        padding: 5.2vh 5.13vw 5.2vh 5.13vw;
    }

}  

.copyCard{
    margin-top: 8px;
}

.copyIcon{
    cursor: pointer;
}

.copyIconCol{
    display: flex !important;
    align-items: center;
    justify-content: end;
}
.btnDismiss{
    text-align: center;
    margin-top: 25px;
    a{
        font-size: 12px;
        font-weight: bold;
        color: #000099;
        text-decoration: underline;
    }
}
.copyCode{
    font-size: 12px;
    color: #000099;
}

