.ib-0 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px !important;
  line-height: 120% !important;
  color: #161616 !important;
  margin-bottom: 0px !important;
  .ant-form-item-label > label {
    font-size: 12px!important;
  }
}
.ant-form-item-label > label {
  font-size: 12px!important;
}
.ib-1 {
  border-radius: 5px !important;
  /* border: 1px solid #2E2E2E; */
  height: 40px;
  &:active {
    border-color: #000 !important;
  }
}
.ant-input {
  font-size: 12px !important;
}
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #000 !important;
}
.ant-input-affix-wrapper-focused {
  border-color: #000 !important;
}
.ant-input:hover {
  border-color: #000 !important;
}
.ant-input:focus {
  border-color: #000 !important;
}
.ant-form-item-has-error {
  label {
    color: #ee0000 !important;
  }
}
.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  display: inline-block;
  margin-right: 4px;
  color: #ee0000;
  font-size: 0.83vw;
  font-family: IBM Plex Sans;
  line-height: 1;
  content: "*";
}

.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none !important;
}

.ant-input-suffix {
  font-weight: 18px;
}
.ant-form-item-explain-error {
  font-size: 12px;
  color: #dc143c !important;
}
