.orgListContainer {
    padding: 0.938rem 0px;
  }
  .organisationListCard {
    background: #FFFFFF !important;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 0.938rem !important;    
    border-radius: 10px !important;
    cursor: pointer;
    .ant-card-body{
      padding: 0px !important;
    }
 &:hover{
        box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.2);
    }  
  }
  .organisationName{
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    display: flex;
    align-items: center;
    text-align: left;
    color: #000099;
    
  }

  .organisationButton{
    background: #FFFFFF;
    border: 1px solid rgba(151, 151, 151, 0.3);
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px;  }

  

  .orgContactNameSlicing{
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    line-height: 200%;
    display: flex;
    align-items: center;
    color: #2E2E2E;
    justify-content: left;
    width: 100%;
    
  }
  .actGrn{
    justify-content: right !important;
    display: block !important;
  }

  .orgCard{
      align-items: center;
  }
 
.activeAccount{
  padding-right: 5px;
}
  
.orgColumn1{
  padding: 0.875rem 0px 0.875rem 1.313rem;
}

.orgColumn2{
  padding: 0.42rem 1.313rem 0.875rem 0px;
}
.orgColumn2 img{
  padding: 0px;
}

.slice{
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
}
.orgDetailSliceCLass{
  margin-bottom: 0;
}
.orgStatusMargin{
  margin-left: 12px;
}

.orgNameContentSlicing{
  width: 100%;
}
.marginClassOrg{
  margin-left: 30px;
}


.platformloginOrg{
  .orgListHead{
    margin-left: 0px !important;
  }

}
.orgListHeadMginleft{
  margin-left: 25px !important;
}