.mainPage{
    background-color: lavender;
    height: 100vh;
}

.resetLink{
     .ant-card-body{
        padding-top: 5.25rem !important;
        padding-right: 4.688rem !important;
        padding-left: 4.688rem !important; 
        padding-bottom: 5.188rem !important;
        }
  }
.auth-content button{
    font-family: 'Open Sans';
    color:#000099 ;
    font-weight: 700;
    font-size: 14px;
}
.reset{
    color: #000099;
    text-align: center;
    margin-bottom: 8px;
    margin-top: 0;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: 0.1em;
}

.text{
    color: rgb(15, 1, 1);
    text-align: center;
    margin-bottom: 1.875rem;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    line-height: 120%;
    letter-spacing: 0.05em;
}

.icon{
    padding-right: 0.406rem;
}

.note{
    color: #898989;
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
    line-height: 157%;
    margin-top: 2.625rem;
    margin-bottom: 0;
}
