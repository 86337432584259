.platformUserBrd{
    display: flex;
    justify-content: space-between;
}

.payerListRow{
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    height: 3.438rem;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 120%;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #2E2E2E;
    margin-top: 1.563rem;
    margin-bottom: 1.219rem;
}

.cityStyle{
    display: flex;
    text-align: left;
}

.gridIcon{
    display: flex;
    text-align: end;
}
.payerHeader{
    font-size: 12px;
}

