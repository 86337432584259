.cellRenderer{
    display: flex;
    column-gap:1.25rem;
    .input-container{
        width: 70%;
    }
    .icon-container{
        display: flex;
    column-gap:1.25rem;
    }
}
.orgcellRender{
    display: grid !important;
    grid-template-columns: 4fr 1fr;
    grid-column-gap: 1.25rem;
    .icon-container{
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
    }
    .input-container{
        width: 100% !important;
    }
}