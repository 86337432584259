.addRoleHeader{
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 120%;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #000099;
}
.editCard{
    padding-top: 1.25rem !important;
}
.saveButton{
    background: transparent;
}
.addRoleRow{
    padding-bottom: 27px;
    padding-inline-end: 28px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.card{
    border-radius: 10px !important;
}
.btndelete{
    padding-right: 0px !important;
}
@media(max-width:992px){
    .btndelete{
        padding-right: 0px !important;
    }
}