.orgNameSlicing {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #000099;
    margin: 0px!important;
  }
  .Address{
    margin-bottom: 10px;
  }
  .orgStateSlicing {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    line-height: 200%;
    display: flex;
    align-items: center;
    color: #2e2e2e;
  }
  
  .activeAccount {
    padding-right: 5px;
  }
  
  .mobileClass{
    margin-left: 1.25rem;
    margin-right: 5px;
  }
  
  
  .familyCardDetail{
      background: #ffffff;
      box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
      border-radius: 10px !important;
      cursor: pointer;
      
      .ant-card-body{
        padding: 0px !important;
      }
      }
    
      .orgRow2{
        padding: 0px 1.4vw 0.3vh 1.4vw;
        .orgIconSlicing{
          text-align: center !important;
        }
      }
      
      .orgRow1{
        padding: 1.2vh 1.4vw 0px 1.4vw;
      }
  
  .menuProviderList{
    background: rgba(137, 137, 137, 0.08);
  }
  
  .addText{
    display: flex;
    align-items: center;
    text-align: center;
    font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  line-height: 120%;
    color: #898989;
  }
  
  .addFamilyCard{
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 10% !important;
    background: rgba(137, 137, 137, 0.1) !important;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px !important;
    min-height: 200px;
  }