.patientDtl>b{
margin-right: 28px;
font-family: 'Open Sans';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 120%;
align-items: center;
letter-spacing: 0.05em;
color: #2E2E2E;
}
.ptntGender{
    margin-top: 15px;
    color: #2E2E2E;
}
.communicationCheck{
    margin-right: 5px;
}
.statusDtl>b{
    margin-right: 19px;
}
.statusDtl{
    color: #2E2E2E;
}
.patientName{
font-family: 'Open Sans';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 120%;
letter-spacing: 0.1em;
color: #000099;
}
.btnRadio span{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px !important;
    line-height: 120%;
    color: #2E2E2E !important;
}
.ptntContanctInfo{
    margin-top: 22px;
    color: #2E2E2E;
   display: flex;
}
.ptntRole{
    margin-top: 30px;
}
.patientNametra{
    padding-left: 10px;
    font-family: 'Open Sans';
font-style: normal;
font-weight: 600;
font-size: 14px;
height: 40px;
width: 160px;
}
.patientNametra .patientName{
    font-size: 14px;
    font-family: 'Open Sans';
font-style: normal;
font-weight: 600;
width: 99%;
}
.scrollContent .cardRow{
    .ant-card-body{
        min-height: 72.5vh;
    }
}
.ant-picker-large .ant-picker-input > input {
    font-size: 12px !important;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
    background: rgb(0 0 0 / 8%) !important;
}
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled), .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
    font-weight: 600;
    background-color: rgb(0 0 0 / 8%) !important;
}
.ant-cascader-checkbox-checked .ant-cascader-checkbox-inner {
    background-color: #000090 !important;
    border-color: #000090 !important;
}
.ant-picker-today-btn {
    color: #0E3077 !important;
    font-weight: 600;
}
.ProPatientDetails, .DailySchedule{ 
    min-height: 72.5vh;
    
b{
    margin-right: 12px;
font-family: 'Open Sans';
font-style: normal;
flex-shrink: 0;
font-weight: 600;
font-size: 14px;
line-height: 120%;
align-items: center;
letter-spacing: 0.05em;
color: #2E2E2E;
text-transform: uppercase;

}
.patientDtl{
    margin-bottom: 5px;
    color: #2E2E2E;
    
}
.ptntContanctInfo{
    margin-top: 8px;

}

.ant-checkbox-input{
    cursor:not-allowed;
}
.contactPtntDetail{
    margin-bottom: 8px;
    margin-right:35px;
}
.patientsprofile{
    text-align: center;
}
}
@media only screen and (min-width:991px)and(max-width:1500px){
    .patientNametra{
        padding-left: 25px;
    }
}
@media (max-width:992px){
    .sb-avatar--icon{
        margin-left: 0px !important;
    }
    
}
.chckPtnt{
    .ant-checkbox-wrapper{
        color: #2E2E2E !important;
    }
    .ant-checkbox-disabled + span{
        color: #2E2E2E !important;
    }
}

.mobileCLass2{
    margin-left: 2px;
    margin-right: 1.292rem;
}
.ProPatientDetails .patientDtl, .DailySchedule .patientDtl {
    margin-bottom: 5px;
    color: #2E2E2E;
}
.hmrnPatientDetails{
    display: flex;
}