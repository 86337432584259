.pulseTxt{
    font-family: Open Sans;
font-style: normal;
font-weight: 600;
line-height: 180%;
color: #2E2E2E;
display: flex;
align-items: center;
letter-spacing: 0.05em;
}

.pulseNmbr{
    font-family: Open Sans;
font-style: normal;
font-weight: normal;
line-height: 180%;
align-items: center;
letter-spacing: 0.05em;

color: rgba(46, 46, 46, 0.8);
}

.downArrow{
    padding-left: 3px;
}