


.radioButton{
    margin-top: 0.938rem;
    margin-left: 1.875rem;
}

.checkboxEmail{
    position: relative;
    left: 6.25rem;
    top: 1.25rem;
}

.patientUserName{
    margin-top: -1.313rem;
}
.familyAddDtl{
margin-bottom: 25px;
}

.mrnText{
    color: #898989;
}

.dobPicker{
box-sizing: border-box;
border-radius: 5px;
margin-top: 5px;
width: 100%;
height: 3.125rem;
font-size: 12px!important;
}
.scheduleTable .ant-picker-input > input{
    font-size: 12px !important;
}

.familyRelation{
    padding-top: 20%;
}
.addFamilyUpload{
    position: absolute;
    bottom: 18%;
    left: 38%;
}
@media (max-width:1300px){
    .addFamilyUpload {
        position: absolute;
        bottom: 25%;
        left: 32%;
    }
}
.addFamilyCamera{
    position: absolute;
    bottom: 30%;
      left: 35%;
}
@media(min-width:840px)and (max-width:1024px){
    .addFamilyCamera{
      position: absolute;
      bottom: 30%;
        left: 27%;
    }
  }
  @media(min-width:768px)and (max-width:839px){
    .addFamilyCamera{
      position: absolute;
      bottom: 30%;
        left: 24%;
    }
  }
  @media(min-width:1621px)and (max-width:1800px){
    .addFamilyCamera{
      position: absolute;
      bottom: 23%;
      left: 37%;
    }
  }
  @media(min-width:1801px){
    .addFamilyCamera {
      position: absolute;
      bottom: 19%;
      left: 38%;
  }
  }
  @media(min-width:1320px) and (max-width:1620px){
    .addFamilyCamera {
        position: absolute;
        bottom: 22%;
        left: 38%;
    }
  }