.auth-layout{
    background: #EAEEF3;
    height: 100vh;
    overflow-y: auto;
}
.auth-layout::-webkit-scrollbar {
    width: 5px;
  }
    
    /* Track */
  .auth-layout::-webkit-scrollbar-track {
    border-radius: 10px;
    background: #9e9e9e71;
  }
     
    /* Handle */
  .auth-layout::-webkit-scrollbar-thumb {
    background: #000999; 
    border-radius: 5px;
  }

.auth-header{
    width: 100%;
    // height: 8vh !important;
    padding-top: 1.875rem !important;
    padding-left: 1.875rem !important;
    .logo{
        // height:9.577vh;
        height: auto;
        width:13.8888vw;
    }
}
.auth-footer{
    position: absolute;
    bottom: 5px;
    text-align: center;
    width: -webkit-fill-available;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    line-height: 120%;
    color: rgba(46, 46, 46, 0.8);
}
