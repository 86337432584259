.NewProviderRow{
    margin-top: 4.125rem;
}
.provider{
    border-radius: 10px;
}
.deleteIcon{
    text-align: end;
}

.providerTable{
    .ant-table-cell{
        text-align: center;
    }
}
#providerSearch .searchibtn{
    text-align: center;
    .secondary{
        width: 40px;
        height: 40px;
        background: #fff;
        display: initial !important;
    }
}


@media(max-width:992px){
    .centreTable thead tr th {
        font-style: normal;
        font-weight: 600;
        font-size: 12px!important;
        text-align: center;
        text-transform: uppercase;
        color: #000099;
        background: #F5F6FA;
        padding: 10px 10px;
    }
    .ant-table-tbody > tr > td{
        padding: 10px 10px;
        font-size: 12px!important;
    }
    .familyModal .ant-modal {
        width: 65%!important;
    }
}
.NewProviderRow{
    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
      -webkit-text-fill-color: #333 !important;
      -webkit-box-shadow: 0 0 0px 1000px #ffff inset;
      transition: background-color 5000s ease-in-out 0s;
    }
}

