.mainPage{
    background-color: lavender;
    height: 100vh;
}

.Card{
  border-radius: 10px !important;
  width: 34.72vw;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  &:hover{
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.3);
  }
}

.loginPage{
  .ant-card-body{
    padding-top: 3.375rem !important;
    padding-right: 4.688rem !important;
    padding-left: 4.688rem !important; 
    padding-bottom: 2.125rem !important;
  }
  }
 

.login{
    display: flex; 
    text-transform: uppercase;
    font-size: 1.041vw;
    justify-content: center;
    align-items: center;
    color: rgb(12, 8, 1);
    width: 100%;
    height: 2.5rem;
    background-color: rgb(123, 123, 160);
    border-radius: 5px;
    margin-bottom: 1.875rem;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.1s;
}

.para{
    color: #000099;
    text-align: center;
    margin-bottom: 7px;
    margin-top: 0;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: 0.1em;
}

.para2{
    color:#2E2E2E;
    text-align: center;
    margin-top: 0;
    margin-bottom: 0;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    line-height: 120%;
}

.forgotLink{
   
    text-align: start;
    margin-top:20px;
    margin-bottom: 0;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    line-height: 120%;
    a{
        color:#000099
        ;
    }
   
    }
.help{
    color:rgb(124, 119, 120);
    margin-top: 1.125rem;
    margin-bottom: 0;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    line-height: 157%;
    a{
        color:#000099
        ;
    }
}

.user{
    padding-bottom: 1rem;
    padding-top: 1.5rem;
}

.pass{
    padding-bottom: 1.188rem;
}

.icon{
    padding-right: 0.406rem;
}
.errorMessage{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    color: #ff4d4f;
}
.btnlogin{
    border: 1px solid #000099 !important;
box-sizing: border-box;
border-radius: 5px !important;
font-family: 'Open Sans';
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 19px;
display: flex;
align-items: center;
text-align: center;
letter-spacing: 0.05em;
text-transform: uppercase;
color: #000099;
}
.btnlogin span{
    font-size: 20px !important;
    margin-right: 8px !important;
    width: 18px !important;
    height: 18px !important;
}
.changePass,.loginPage ,.resetLink,.change{
    width: 100% !important;
}
@media only screen and (max-width: 998px) {
    .Card{
        width: 100%
    }
}
.GreenLink{
    color: green;
}

.termsConditions{
    color: #000099;
    cursor: pointer;
}