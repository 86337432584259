.providerDailyRoutine{
font-family: 'Open Sans';
font-style: normal;
font-weight: 600;
font-size: 12px;
}
.proDashText{
font-family: 'Open Sans';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 8px;
margin-bottom: 0px;
}
.totalPatientTxt{
font-family: 'Open Sans';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 8px;
color: #898989;
margin-bottom: 0px;
}
.grpIcon{
    color: #000099;
    font-size: 40px !important;
}