.centreRenderer{
    height: "-webkit-fill-available";
    align-items: flex-start;
    align-items: center;
    display: flex;
    column-gap:1.25rem;
    justify-content: center;
    .input-container{
        width: 70%;
    }
    .icon-container{
        display: flex;
    column-gap:1.25rem;
    }
    .icon-container img:hover{
        cursor: pointer;
    }
}
