.duration-filter {
    padding: 8px 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    border: 1px solid rgba(137, 137, 137, 0.3);
    border-radius: 5px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    color: #2E2E2E;

    span {
        padding: 0px 1.25rem 0px 1.25rem;
    }
}

.durationFiltermap {
    justify-content: space-around !important;
}

.refreshtext {
    font-size: 12px !important;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 32px;
    align-items: center;
    color: #2E2E2E;
}

.filterRow {
    padding-right: 2vw;
    padding-left: 1.5vw;
    align-items: center;
    margin-bottom: 0.875rem;
}

.filterPDetails {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ant-table-body::-webkit-scrollbar {
    width: 6px;
    /* width of the entire scrollbar */
}


.ant-table-body::-webkit-scrollbar-thumb {
    background-color: #000999;
    /* color of the scroll thumb */
    border-radius: 5px;
    /* roundness of the scroll thumb */
}

.ant-table-body::-webkit-scrollbar-track {
    border-radius: 10px;
    background: #9e9e9e71;
}

.readings-card {
    box-shadow: 0px 1px 2px rgb(31 41 55 / 8%);
    border-radius: 8px !important;
    font-family: Open Sans;
    font-style: normal;
    height: 400px;

    .ant-card-body {
        padding: 0.75rem !important;
    }
}

.readingTitle {
    font-weight: 600;
    line-height: 2rem;
    color: #2E2E2E;
    text-transform: capitalize;
    margin-left: 10px;
}

.Temperature .units span,
.weight .units span {
    color: #898989;
}

.readings-card span.active {
    font-weight: bold;
    color: #2E2E2E
}

.readingsModal {
    .ant-modal {
        width: 80vw !important;
        margin: 0px 0px !important;

        .ant-modal-content {
            border-radius: 10px !important;
        }

        .ant-row-middle {
            align-items: center;
            margin-top: 10px;
        }
    }

    .expandedTitle {
        .readingTitle {
            line-height: 1.2rem;
        }
    }

}
.expandedModalView{
    background-color:rgb(126 123 123 / 81%) !important
}
.readingDataIcon {
    height: 30px;
    width: 30px;
}

.readings-additional-text {
    line-height: 120%;
    letter-spacing: 0.02em;
    color: #2E2E2E;
}

.icons {
    text-align: center;
    cursor: pointer;
}

.readingsTable {
    height: initial !important;
    margin-top: 15px !important;
    
    thead {
        tr {
            th {
                word-wrap: break-word;
                background-color: #F5F6FA !important;
                height: 30px !important;
                text-align: center;
            }
        }

    }

    tbody {
        td {
            font-size: 12px;
            border-left: 1px solid #cccccc !important;
            height: 40px;
        }

    }

    .readings-card
}

.thresholdHeading {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #2E2E2E;
}

.expandedViewThresholds {
    .ant-divider{
        border-top: 2px solid rgba(0, 0, 0, 0.06) !important;
    }
    .tblThreshold{
        table{
            margin-top: 0px !important;
            box-shadow: 1px 1px 2px 1px rgb(31 41 55 / 8%);
        }
      

     
    }
    .thresholdHeading1{
        font-size: 10px !important;
    }
    .tblThreshold{
        font-size: 10px !important;
        table{
            thead{
                tr{
                    th{
                        border: 1px solid #cccccc !important;
                        font-family: 'Open Sans';
                        font-style: normal;
                        font-weight: 600 !important;
                        font-size: 10px !important;
                        line-height: 120%;
                        align-items: center;
                        text-align: center;
                        text-transform: unset !important;
                        color: #2E2E2E !important;
                    }
                }
             
            }
            tbody{
                tr{
                    td{
                        font-size: 10px !important;
                    }
                }
            }
        }
    }
    .ant-divider-horizontal {
        margin: 8px 0px 0px !important;
    }
}

.readings-card, .expanded {
    .units {
        font-size: 12px;
        display: flex;
        align-items: center;
    }

    .units span {
        cursor: pointer;
    }

    span.active {
        font-weight: bold;
    }

    .ant-col.ant-col-2.icons {
        text-align: center !important;
    }

    .ant-col.ant-col-3.icons {
        text-align: end !important;
    }
}

.tblThreshold {
    tbody td {
        font-size: 12px;
        border-left: none !important;
    }

    tbody tr td:first-child,
    thead tr th:first-child {
        border-right: 1px solid #cccccc
    }

    tbody tr td,
    thead tr th {
        border-right: 1px solid #cccccc !important
    }

    thead tr th {
        color: #2E2E2E !important;
        font-weight: 600 !important;
    }

    .ant-table-tbody>tr>td {
        border-bottom: none !important;
        transition: background 0.3s;
    }

    .ant-table-thead>tr>th {
        position: relative;
        color: rgba(0, 0, 0, 0.85);
        font-weight: 500;
        text-align: left;
        background: #fff !important;
        border-bottom: none !important;
        transition: background 0.3s ease;

    }

    .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
        background-color: #fff !important;
    }
}

.tblBloodP {
    padding-right: 20px;

    .scrollContent {
        padding-right: 0px !important;
    }
}

.ant-modal-close-x {
    width: initial !important;
    height: initial !important;
    transform: translate(-12px, 0px);
    margin-top: 10px;
    line-height: 0px !important;

    svg {
        height: 18px;
        width: 18px;
    }
}

.utilIcon {
    width: 22px;
    height: 22px;
}

.icnExpnd {
    margin-right: 6px;
}

.txtAvg {
    vertical-align: sub;
}

.readings-additional-text b {
    font-size: 10px !important;
    text-transform: lowercase !important;
}

.expandedView b {
    font-size: 12px !important;
}

.spiroHead {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600 !important;
    font-size: 10px !important;
    line-height: 120%;
    align-items: center;
    text-align: center;
    text-transform: unset !important;
    color: #2E2E2E !important;
}

.spirometerTable {
    margin-top: 15px;

    th {
        padding: 13px 8px !important;
    }
}

.bldprsrTable {
    th {
        padding: 4px 8px;
        background-color: #F5F6FA;
    }
}

.assnDevice {
    color: #2E2E2E;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
}

.expandedTitle {
    display: flex;
    align-items: center;
    column-gap: 10px;
}

.daysViewCount {
    color: #2E2E2E;
    font-size: 12px;
}

.zoomLabel {
    color: #000999;
    cursor: pointer;
    font-size: 12px
}

.durationFilterModal {
    justify-content: space-between !important;
}

.durationFilterModal span {
    padding: 0px 0.25rem 0px 0.25rem !important;
    font-size: 12px;
}

.chartRender {
    padding-top: 5px;

    canvas {
        padding-bottom: 10px !important;
    }

}

@media(max-width:992px) {
    .readings-card {
        box-shadow: 0px 1px 2px rgb(31 41 55 / 8%);
        border-radius: 8px !important;
        font-family: Open Sans;
        font-style: normal;
        height: auto !important;
    }

    .OrderChange {
        order: 5;
        margin-top: 10px;
        position: relative;
        left: 30%;
        top: 0;
        width: fit-content;
    }

    #glucolegendRowExpand {
        left: 0px !important;
    }

    .plusOrderChange {
        order: 5;
        margin-top: 10px;
        position: relative;
        left: 15%;
        top: 0;
        width: fit-content;
    }

    .durationFilterModal {
        justify-content: space-between !important;
    }

    .durationFilterModal img {
        width: 18px;
        height: 18px;
    }

    .durationFilterModal span {
        padding: 0px 0.25rem 0px 0.25rem !important;
        font-size: 12px;
    }

    .thresholdHeading1 {
        margin-top: 10px;
    }

    .assnDevice {
        margin-top: 20px;
    }

    .ant-checkbox-wrapper+.ant-checkbox-wrapper {
        margin-left: 0px !important;
    }
}

.navigationIcon {
    cursor: pointer;
}

.expandedViewThresholds {
    margin-top: 15px;
}
.latestReading{
    text-transform: capitalize !important;
    b{
        text-transform: capitalize !important;
    }
}
#weightChartDiv ,#temperatureChartDiv {
    width: 103%
}
@media(min-width:992px)and(max-width:1539px) {

    #chartDiv,
    #glucoChartDiv,
    #temperatureChartDiv {
        width: 106%;
        padding-top: 5px;
    }

    #pulseChartDiv {
        width: 105%;
    }

    #weightChartDiv {
        width: 105%
    }
}

//add media for expanded view
@media(min-width:770px)and (max-width:1539px) {

    #chartDivExpanded,
    #glucoChartDivExpand,
    #weightChartDivExpand,
    #pulseChartDivExpand,
    #temperatureChartDivExpand {
        width: 105% !important;
    }
}
@media(max-width:768px){
    #chartDivExpanded,
    #glucoChartDivExpand,
    #weightChartDivExpand,
    #pulseChartDivExpand,
    #temperatureChartDivExpand {
        width: 109% !important;
    }
}