.auditTable{
    font-family: 'Open Sans';
    thead{
        background: #F5F6FA;
    tr{
        th{
            font-family: 'Open Sans';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 120%;
align-items: center;
text-align: center;
background-color: #F5F6FA;
color: #2E2E2E;
        }
    }
    }
    td{
        text-align: center;
        font-size: 12px;
    }
    td:last-child{
        text-align: left;
    }
    
.jsonDataCell{
    text-align: left;
    align-items: start;
    text-transform: capitalize;
}
}
